import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { Logo, Icon } from 'components/common';
import { ReactComponent as BarsIcon } from 'assets/icons/bars.svg';

function Header({ onMenu }) {
  // const setLang = lang => {
  //   window.localStorage.setItem('lang', lang);
  //   window.location.reload();
  // };

  return (
    <Flex justifyContent="center" alignItems="center" height={60}>
      {onMenu && (
        <Text variant="mainMenuLink" onClick={onMenu}>
          <Icon as="span" svg={BarsIcon} size={22} color="navyBlue" />
        </Text>
      )}
      {/* <Flex sx={{ position: 'absolute', right: '5%' }}>
        <Text onClick={() => setLang('en')} mr={5}>
          EN
        </Text>
        <Text onClick={() => setLang('nl')}>NL</Text>
      </Flex> */}
      {/* <Logo /> */}
    </Flex>
  );
}

Header.propTypes = {
  onMenu: PropTypes.func
};

export default Header;
