import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Icon } from 'components/common';

function Logo(props) {
  const { icon } = props
  const size = icon ? 128 : 36
  const margin = icon ? 16 : 6

  return (
    <Flex flexDirection="row" alignItems="center" {...props}>
      <Box sx={{ borderRadius: 'circle' }} bg="tertiary" width={size} height={size} mr={margin} />
      <Box sx={{ borderRadius: 'circle' }} bg="brand" width={size} height={size} mr={margin}>
        { icon && <Icon p={15} as="span" svg={icon} size={96} color="white" /> }
      </Box>
      <Box sx={{ borderRadius: 'circle' }} bg="brandDark" width={size} height={size} />
    </Flex>
  );
}

Logo.propTypes = {
  icon: PropTypes.object
};

export default Logo;
