import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { TopicCard } from 'components/common';

function TopicCarousel({ allTopics, topic, index, onChangeTopic, onChangeIndex }) {
  const handleChangeIndex = newIndex => {
    onChangeIndex(newIndex);
  };
  const handleChangeTopic = (newTopic, newIndex) => {
    handleChangeIndex(newIndex);
    onChangeTopic(newTopic.id);
  };

  return (
    <SwipeableViews
      enableMouseEvents
      style={{ paddingRight: '30%' }}
      index={index}
      onChangeIndex={handleChangeIndex}
    >
      {allTopics.map((t, i) => (
        <TopicCard
          key={t.id}
          id={t.id}
          title={t.label}
          selected={topic === t.id}
          onClick={() => handleChangeTopic(t, i)}
        />
      ))}
    </SwipeableViews>
  );
}

TopicCarousel.propTypes = {
  allTopics: PropTypes.array,
  index: PropTypes.number,
  topic: PropTypes.string,
  onChangeIndex: PropTypes.func,
  onChangeTopic: PropTypes.func
};

export default TopicCarousel;
