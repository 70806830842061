import React from 'react';
import PropTypes from 'prop-types';
import { ModalHeader, FluidContainer } from 'components/common';
import { Flex, Box, Text } from 'rebass';
import firebase from 'services/firebase';
import { isMobile } from "react-device-detect";

function ResultsInformationModal({ onClose, ...rest }) {
  firebase.logEvent('eh_screen_view', {event_category: 'tos_pp_modal'});
  const width = (isMobile)?'96vw':340

  return (
    <FluidContainer flex={1} px={0} sx={{ overflowY: 'scroll' }}>
      <ModalHeader onClose={onClose} pt={12} sx={{bg:'#F2F2F2'}}>
        Information and Comments
      </ModalHeader>
      <Box variant="infoModalBg" px={16} pb={36}>
        <Text>
          These scores are not for diagnostic purposes. Any diagnosis should be made with a clinical interview.
        </Text>
        <Text my={12}>
          <span style={{fontWeight:'600'}}>Depression Score</span><br/>
          The survey <span style={{fontWeight:'600'}}>"PHQ-8"</span> stands for <span style={{fontWeight:'600'}}>Patient Health Questionnaire</span>. This 8-question survey is used
          by clinical professionals to understand the state of your emotional health with respect
          to your level of <span style={{fontWeight:'600'}}>depression</span>. A higher score indicates a higher level of depression.
        </Text>
        <Flex>
          <Box py={4} width={1/6} color='navyBlue'>
            <Text fontWeight='bold'>Score</Text>
          </Box>
          <Box py={4} width={2/9} color='navyBlue'>
            <Text fontWeight='bold'>Classification</Text>
          </Box>
          <Box py={4} width={11/18} color='navyBlue' sx={{textAlign: 'center'}}>
            <Text fontWeight='bold'>Comments</Text>
          </Box>
        </Flex>
        <Flex>
          <Box py={4} width={1/6}>
            <Text>5-9</Text>
          </Box>
          <Box py={4} width={2/9}>
            <Text>Mild</Text>
          </Box>
          <Box py={4} width={11/18}>
            <Text>Menlo Mental Health Service is always here if you want to talk.</Text>
          </Box>
        </Flex>
        <div style={{background:'#979797', height:1}}/>
        <Flex>
          <Box py={4} width={1/6}>
            <Text>10-19</Text>
          </Box>
          <Box py={4} width={2/9}>
            <Text>Moderate</Text>
          </Box>
          <Box py={4} width={11/18}>
            <Text>
              If you would like additional support, please <a href="https://www.menlo.edu/student-life/mental-health-services/" target="_blank">visit Menlo Mental Health Services</a> Clinic to setup an appointment.
            </Text>
          </Box>
        </Flex>
        <div style={{background:'#979797', height:1}}/>
        <Flex>
          <Box py={4} width={1/6}>
            <Text>20-24</Text>
          </Box>
          <Box py={4} width={2/9}>
            <Text>Severe</Text>
          </Box>
          <Box py={4} width={11/18}>
            <Text>
              It sounds like you are going through a difficult time and we would like to help. <a href="https://www.menlo.edu/student-life/mental-health-services/" target="_blank">Please visit</a> us today, so we can set up an appointment. We look forward to meeting you!
            </Text>
          </Box>
        </Flex>
        <div style={{background:'#979797', height:1}}/>
        <Text my={16}>
          <span style={{fontWeight:'600'}}>Anxiety Score</span><br/>
          The survey <span style={{fontWeight:'600'}}>"GAD"</span> stands for 
          <span style={{fontWeight:'600'}}> Generalized Anxiety Disorder</span>. This 7-question survey is used
          to understand the state of your emotional health with respect
          to your level of anxiety. A higher score indicates
          a higher level of <span style={{fontWeight:'600'}}>anxiety</span>. 
          A higher score indicates a higher level of anxiety.
        </Text>
        <Flex>
          <Box py={4} width={1/6} color='navyBlue'>
            <Text fontWeight='bold'>Score</Text>
          </Box>
          <Box py={4} width={2/9} color='navyBlue'>
            <Text fontWeight='bold'>Classification</Text>
          </Box>
          <Box py={4} width={11/18} color='navyBlue' sx={{textAlign: 'center'}}>
            <Text fontWeight='bold'>Comments</Text>
          </Box>
        </Flex>
        <Flex>
          <Box py={4} width={1/6}>
            <Text>5-9</Text>
          </Box>
          <Box py={4} width={2/9}>
            <Text>Mild</Text>
          </Box>
          <Box py={4} width={11/18}>
            <Text>Menlo Mental Health Service is always here if you want to talk.</Text>
          </Box>
        </Flex>
        <div style={{background:'#979797', height:1}}/>
        <Flex>
          <Box py={4} width={1/6}>
            <Text>10-15</Text>
          </Box>
          <Box py={4} width={2/9}>
            <Text>Moderate</Text>
          </Box>
          <Box py={4} width={11/18}>
            <Text>
              If you would like additional support, please <a href="https://www.menlo.edu/student-life/mental-health-services/" target="_blank">visit Menlo Mental Health Services</a> Clinic to setup an appointment.</Text>
          </Box>
        </Flex>
        <div style={{background:'#979797', height:1}}/>
        <Flex>
          <Box py={4} width={1/6}>
            <Text>16-21</Text>
          </Box>
          <Box py={4} width={2/9}>
            <Text>Severe</Text>
          </Box>
          <Box py={4} width={11/18}>
            <Text>
              We know that you are going through a difficult time and we would like to help. <a href="https://www.menlo.edu/student-life/mental-health-services/" target="_blank">Please visit</a> us today, so we can set up an appointment. We look forward to meeting you!
            </Text>
          </Box>
        </Flex>

      </Box>
    </FluidContainer>
  );
}

ResultsInformationModal.propTypes = {
  onClose: PropTypes.func
};

export default ResultsInformationModal;
