import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { Flex, Box, Text } from 'rebass';
import AudioVisualizer from 'utils/AudioVisualizer';
import AudioRecorder from 'utils/AudioRecorder';
// import NewAudioRecorder from 'utils/NewAudioRecorder';
import Timer from 'utils/Timer';
import createMicSource from 'utils/Microphone';
import { Button, FluidContainer, FullLoading } from 'components/common';
import RecordingActions, { RecordingSelectors } from 'redux/RecordingRedux';
import { AppSelectors } from 'redux/AppRedux';
import logger from 'services/logger';
import confirm from 'utils/modal';
import getRecordingHelpText from 'utils/recordHelp';
import { FormattedMessage } from 'react-intl';
// import { downloadBlob } from 'utils/downloader';
import firebase from 'services/firebase';

import Tutorial from './Tutorial';
import SoundSpectrum from './SoundSpectrum';
import TopicCarousel from './TopicCarousel';
import QuestionCarousel from './QuestionCarousel';

// const LIMIT = 4 * 60;
// const MAX_LIMIT = 10 * 60;
const DEBOUNCE_MS = 500;

class SoundRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      prevTime: 0,
      currentTime: 0,
      topicChangeCount: 0,
      wasRecording: false,
      isSystemPause: false,
      reachedEnd: false,
      isTutorial: false,
      promptChangeCount: 1,
      helperText: '',
      indexTopic: 0,
    };

    this.debouncedResume = debounce(this.resumeRecording, DEBOUNCE_MS);
  }

  async componentDidMount() {
    firebase.logEvent('eh_screen_view', { event_category: 'active_session' });

    const { resetRecording } = this.props;
    resetRecording();
    this.createSource();
  }

  componentWillUnmount() {
    this.audioContext.close();
    // this.audioRecorder.mediaRecorder.stream
    //   .getTracks()
    //   .forEach(track => track.stop());
  }

  get limitMins() {
    const { partner } = this.props;

    return (get(partner, 'sessionConfig.recordingDuration') || 180) / 60;
  }

  get limit() {
    return this.limitMins * 60;
  }

  get maxLimit() {
    const { partner } = this.props;

    return get(partner, 'sessionConfig.maxRecordingDuration') || 600;
  }

  get helperText() {
    const {
      prevTime,
      currentTime,
      topicChangeCount,
      promptChangeCount
    } = this.state;
    const { startTimestamp, topic, recording } = this.props;
    const passedTime = Math.floor(prevTime + currentTime);

    return getRecordingHelpText(
      {
        startTimestamp,
        topic,
        topicChangeCount,
        promptChangeCount,
        recording,
        passedTime,
        currentTime
      },
      this.limit
    );
  }

  get buttonText() {
    const { recording, startTimestamp, topic } = this.props;
    const { reachedEnd, isSystemPause } = this.state;

    if (!startTimestamp && topic) {
      return '';
    }

    if (reachedEnd) {
      return '';
    }

    if (recording && startTimestamp) {
      return <FormattedMessage id="pause" />;
    }

    if (!recording && startTimestamp) {
      return isSystemPause ? (
        ''
      ) : (
          ''
        );
    }

    return '';
  }

  onProcess = passedTime => {
    const { prevTime } = this.state;
    const { recording } = this.props;
    logger.log('Record progress', prevTime, passedTime);

    if (prevTime + passedTime > this.maxLimit && recording) {
      this.finishRecording();
    }

    // The user can continue talking after the limit
    if (prevTime + passedTime > this.limit) {
      this.setState({ reachedEnd: true });
    }

    this.setState({
      currentTime: passedTime
    });
  };

  createSource = async () => {
    try {
      const { audioContext, stream, source } = await createMicSource();
      const visualMainElement = document.querySelector('#recordSpectrum');
      this.audioContext = audioContext;
      this.audioVisualizer = new AudioVisualizer(
        audioContext,
        source,
        visualMainElement
      );
      this.audioRecorder = new AudioRecorder(
        audioContext,
        stream,
        source,
        this.saveSession,
        this.onProcess
      );
      // this.audioRecorder = new NewAudioRecorder(stream, this.saveSession);
      this.timer = new Timer(this.onProcess);
    } catch (e) {
      console.error(e);
      this.setState({
        error:
          'Microphone permission is required for recording audio. Please allow microphone and restart the app'
      });
    }
  };

  startRecording = () => {
    firebase.logEvent('eh_button_click', { event_category: 'active_session', event_action: 'record', event_label: 'RECORD' })

    const { startRecording, topic } = this.props;
    logger.log('Started recording');
    startRecording();
    this.audioVisualizer.start();
    this.audioRecorder.start();
    this.timer.start();
    this.setState({ indexTopic: topic - 1 });
  };

  pauseRecording = () => {
    firebase.logEvent('eh_button_click', { event_category: 'active_session', event_action: 'pause', event_label: 'PAUSE' })

    const { pauseRecording } = this.props;
    const { prevTime, currentTime } = this.state;

    logger.log('Paused recording');

    pauseRecording();

    this.audioVisualizer.stop();
    this.audioRecorder.stop();
    this.timer.stop();

    this.setState({ prevTime: currentTime + prevTime, currentTime: 0 });
  };

  resumeRecording = async () => {
    firebase.logEvent('eh_button_click', { event_category: 'active_session', event_action: 'resume', event_label: 'RESUME' })

    const { resumeRecording, topic } = this.props;
    logger.log('Resumed recording');

    this.setState({ wasRecording: false, indexTopic: topic - 1 });
    await this.audioContext.close();
    await this.createSource();

    resumeRecording();
    this.audioVisualizer.start();
    this.audioRecorder.start();
    this.timer.start();
  };

  finishRecording = async () => {
    firebase.logEvent('eh_button_click', { event_category: 'active_session', event_action: 'finish', event_label: 'FINISH' })

    const { finishRecording, recording } = this.props;
    const { currentTime, prevTime, reachedEnd } = this.state;
    logger.log('Finished recording');

    if (recording) {
      this.pauseRecording();
    }

    if (currentTime + prevTime < this.limit && !reachedEnd) {
      const isBefore2 = currentTime + prevTime < (this.limit / 3) * 2;

      try {
        await confirm({
          title: (
            <FormattedMessage
              id={isBefore2 ? 'session_should_continue' : 'just_a_bit_more'}
            />
          ),
          message: (
            <FormattedMessage
              id={
                isBefore2
                  ? 'ending_result_invalid'
                  : 'please_record_at_least_minutes'
              }
              values={{
                limit: this.limitMins,
                limit2: Math.round((this.limitMins / 3) * 2)
              }}
            />
          ),
          okLabel: (
            <FormattedMessage id={isBefore2 ? 'continue' : 'keep_going'} />
          ),
          cancelLabel: <FormattedMessage id="exit_early" />
        });
        this.resumeRecording();
        return;
      } catch (e) {
        // do nothing and just go ahead to finish
      }
    }

    finishRecording(currentTime + prevTime < (this.limit / 3) * 2);
  };

  saveSession = data => {
    const { saveRecording } = this.props;
    saveRecording(data.blob, data.duration);
    // downloadBlob(data.blob);
  };

  handleClickSpectrum = () => {
    const { recording, startTimestamp, topic } = this.props;
    const { reachedEnd } = this.state;

    if (!topic) {
      this.setState({ helperText: <FormattedMessage id="select_topic_first" /> });
      return;
    }
    this.setState({ helperText: '' });

    if (recording && startTimestamp) {
      this.pauseRecording();
    } else if (!recording && startTimestamp) {
      this.resumeRecording();
    } else {
      this.startRecording();
    }
  };

  handleButtonClick = () => {
    this.finishRecording();
  };

  onChangeTopic = newTopic => {
    const { setTopic, recording, topic } = this.props;
    const { topicChangeCount } = this.state;

    setTopic(newTopic);
    this.setState({ helperText: '' });

    if (newTopic !== topic) {
      firebase.logEvent('eh_screen_swipe', { event_category: 'active_session', event_action: 'topics' })

      this.setState({
        topicChangeCount: topicChangeCount + 1,
        promptChangeCount: 1
      });
    }

    if (newTopic && !recording) {
      this.setState({ isSystemPause: false });
    }
  };

  onNavigateTopic = (indexTopic) => {
    const { recording } = this.props;

    if (recording) {
      this.pauseRecording();
      this.setState({ isSystemPause: true });
    }

    this.setState({ indexTopic})
  };

  onChangeQuestion = index => {
    firebase.logEvent('eh_screen_swipe', { event_category: 'active_session', event_action: 'questions' })

    const { setQuestionId, questions } = this.props;
    const { promptChangeCount } = this.state;

    const currentQuestion = questions[index];
    setQuestionId(currentQuestion.id);
    this.setState({ promptChangeCount: promptChangeCount + 1 });
  };

  onStartTutorial = () => {
    firebase.logEvent('eh_button_click', { event_category: 'active_session', event_action: 'modal', event_label: '?' })
    firebase.logEvent('eh_screen_view', { event_category: 'session_help' })

    const { recording } = this.props;
    if (recording) {
      this.pauseRecording();
    }
    this.setState({ isTutorial: true });
  };

  onFinishTutorial = () => {
    firebase.logEvent('eh_button_click', { event_category: 'session_help', event_action: 'exit', event_label: 'x' })

    this.setState({ isTutorial: false });
  };

  render() {
    const { prevTime, currentTime, isTutorial, error, reachedEnd, indexTopic } = this.state;
    const {
      topics,
      topic,
      questions,
      questionId,
      recording,
      finishing,
      startTimestamp
    } = this.props;

    const questionIndex = questions.findIndex(q => q.id === questionId);

    if (error) {
      return (
        <Flex flexDirection="column" flex={1}>
          <FluidContainer py={20}>
            <Box variant="card2" mb={20} mx={20}>
              {error}
            </Box>
          </FluidContainer>
          <FluidContainer flex={1} />
          <FluidContainer py={20}>
            <Button
              variant="containedPrimary"
              onClick={() => window.location.reload()}
              className="exit-button"
            >
              Restart
            </Button>
          </FluidContainer>
        </Flex>
      );
    }

    return (<>
      <Flex flexDirection="column" flex={1}>
        <Box bg="metallicBlue">
          <FluidContainer mt={20} position="relative">
            <Text variant="topicTitle" mb={5}>
              <FormattedMessage id="pick_your_topic" />
            </Text>
            <Button variant="helpButton" onClick={this.onStartTutorial}>
              ?
            </Button>
          </FluidContainer>
          <FluidContainer mb={20} className="topic-carousel">
            <Box mx={-20}>
              <TopicCarousel
                allTopics={topics}
                topic={topic}
                index={indexTopic}
                onChangeTopic={this.onChangeTopic}
                onChangeIndex={this.onNavigateTopic}
              />
            </Box>
          </FluidContainer>
        </Box>
        <FluidContainer className="question-carousel" mt={10}>
          <Box mx={-20}>
            {topic && questions.length > 0 ? (
              <QuestionCarousel
                questions={questions}
                index={questionIndex || 0}
                onChangeIndex={this.onChangeQuestion}
              />
            ) : (
                <Box variant="cardQuestion" mb={20} mx={20}>
                  <FormattedMessage id="swipe_through_the_topics" />
                </Box>
              )}
          </Box>
        </FluidContainer>
      </Flex>
      <Flex flexDirection="column" flex={1} mb={20} maxHeight={250}>
        <FluidContainer
          alignItems="center"
          flex={1}
        >
          <Text variant="sectionTitle" pb={20} minHeight={50} height={50} sx={{ fontWeight: 400 }}>
            {this.state.helperText || this.helperText}
          </Text>
          <SoundSpectrum
            text={this.buttonText}
            recording={recording}
            progress={Math.min(
              ((currentTime + prevTime) / this.limit) * 100,
              100
            )}
            onClick={this.handleClickSpectrum}
          />
        </FluidContainer>
        <FluidContainer py={20} alignItems="center" className="recorder-container">
          <Button
            variant="text"
            onClick={this.handleButtonClick}
            disabled={!startTimestamp}
            sx={{ color: !startTimestamp ? 'lightgrey' : 'black', outline: 'none' }}
            className="exit-button"
            mt={40}
          >
            <FormattedMessage id={reachedEnd? "finish_recording" : "exit_recording"} />
          </Button>
        </FluidContainer>
        {isTutorial && (
          <Tutorial isOpen={isTutorial} onClose={this.onFinishTutorial} />
        )}
        {finishing && <FullLoading>Saving Record...</FullLoading>}
      </Flex></>
    );
  }
}

SoundRecorder.propTypes = {
  topic: PropTypes.string,
  setTopic: PropTypes.func,
  partner: PropTypes.object.isRequired,
  startRecording: PropTypes.func,
  pauseRecording: PropTypes.func,
  resumeRecording: PropTypes.func,
  finishRecording: PropTypes.func,
  resetRecording: PropTypes.func,
  saveRecording: PropTypes.func,
  questions: PropTypes.array,
  questionId: PropTypes.string,
  setQuestionId: PropTypes.func,
  recording: PropTypes.bool,
  finishing: PropTypes.bool,
  topics: PropTypes.array,
  startTimestamp: PropTypes.instanceOf(Date)
};

const mapStatesToProps = state => ({
  partner: AppSelectors.selectPartner(state),
  topic: RecordingSelectors.selectTopic(state),
  finishing: RecordingSelectors.selectFinishing(state),
  questionId: RecordingSelectors.selectQuestionId(state),
  questions: RecordingSelectors.selectQuestions(state),
  recording: RecordingSelectors.selectRecording(state),
  startTimestamp: RecordingSelectors.selectStartTimestamp(state),
  topics: AppSelectors.selectTopics(state)
});

const mapDispatchToProps = dispatch => ({
  startRecording: () => dispatch(RecordingActions.startRecording()),
  pauseRecording: () => dispatch(RecordingActions.pauseRecording()),
  resumeRecording: () => dispatch(RecordingActions.resumeRecording()),
  finishRecording: finishedEarly =>
    dispatch(RecordingActions.finishRecording(finishedEarly)),
  resetRecording: () => dispatch(RecordingActions.resetRecording()),
  saveRecording: (blob, duration) =>
    dispatch(RecordingActions.saveRecording(blob, duration)),
  setTopic: topic => dispatch(RecordingActions.setTopic(topic)),
  setQuestionId: questionId =>
    dispatch(RecordingActions.setQuestionId(questionId))
});

export default connect(mapStatesToProps, mapDispatchToProps)(SoundRecorder);
