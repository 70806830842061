import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import EnvIndicator from 'containers/layout/EnvIndicator';

import App from './App';
import configureStore from './redux/store';
import theme from './styles/theme';
// import * as serviceWorker from './serviceWorker';

import TranslationProvider from './i18n/TranslationProvider';
import { Box } from 'rebass';
import { isMobile } from "react-device-detect";

const store = configureStore();

console.log('ENV', process.env.REACT_APP_ENV);

const Main = () => (
  <Provider store={store}>
    <TranslationProvider>
      <ThemeProvider theme={theme}>
        <EnvIndicator />
        {!isMobile &&
          <Box className='DesktopApp' sx={{margin:'auto'}}>
            <App />
          </Box>
        }
        {isMobile && <App />}          
      </ThemeProvider>
    </TranslationProvider>
  </Provider>
);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
