import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckedIcon } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as UnCheckedIcon } from 'assets/icons/checkbox-unchecked.svg';
import Icon from '../common/Icon';

function Checkbox({ checked, onChange, ...rest }) {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <Icon
      as="span"
      svg={checked ? CheckedIcon : UnCheckedIcon}
      size={32}
      onClick={handleChange}
      {...rest}
    />
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

export default Checkbox;
