import React from 'react';
import { Box } from 'rebass';

const ENV = {
  demo: 'Demo',
  qa: 'QA',
  'kittreskin-dev': 'Dev'
};

function EnvIndicator() {
  const env = ENV[process.env.REACT_APP_ENV];

  if (!env) return null;

  return (
    <Box
      sx={{ position: 'fixed', right: 50, top: 5, borderRadius: 'md' }}
      px={10}
      bg="red"
      color="white"
    >
      {env}
    </Box>
  );
}

export default EnvIndicator;
