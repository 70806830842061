import { combineReducers } from 'redux';
import { reducer as app } from './AppRedux';
import { reducer as recording } from './RecordingRedux';

const reducers = combineReducers({
  app,
  recording
});

export default reducers;
