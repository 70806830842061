import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik, Field, Form } from 'formik';
import range from 'lodash/range';
import { Flex, Text, Box } from 'rebass';
import { FormattedMessage } from 'react-intl';
import { Button, AlertPanel, FluidContainer } from 'components/common';
import { SelectField } from 'components/formik';
import { convertToAmpmHour, convertToDiff } from 'utils/date';
import schema from './schema';

const WEEKDAYS_OPTIONS = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' }
];
const AMPM_OPTIONS = [
  { value: 'am', label: 'AM' },
  { value: 'pm', label: 'PM' }
];
const HOUR_OPTIONS = range(0, 12).map(i => ({
  value: i,
  label: i
}));
const MINUTE_OPTIONS = range(12).map(i => ({
  value: i * 5,
  label: i * 5 >= 10 ? i * 5 : `0${i * 5}`
}));

class ReminderForm extends Component {
  constructor() {
    super();
    this.state = {
      error: null
    };
  }

  handleSubmit = async (values, actions) => {
    const { onSubmit, onBack } = this.props;

    this.setState({ error: null });
    try {
      await onSubmit({
        reminderHour: convertToDiff(values.hour, values.minute, values.ampm),
        reminderDay: values.day
      });
      onBack();
    } catch (e) {
      console.error(e);
      this.setState({ error: e.message });
      actions.setSubmitting(false);
    }
  };

  renderForm = ({ isSubmitting, isValid }) => {
    const { buttonText } = this.props;
    const { error } = this.state;

    return (
      <FluidContainer flexDirection="column" as={Form} flex={1}>
        <AlertPanel children={error} my={20} />
        <Text color="brandDark">Scheduled Session Day & Time</Text>
        <Flex flex={1} flexDirection="row" my={20}>
          <Box flex={2} mr={10}>
            <Field
              component={SelectField}
              name="day"
              options={WEEKDAYS_OPTIONS}
            />
          </Box>
          <Box flex={1} mr={10}>
            <Field component={SelectField} name="hour" options={HOUR_OPTIONS} />
          </Box>
          <Box flex={1} mr={10}>
            <Field
              component={SelectField}
              name="minute"
              options={MINUTE_OPTIONS}
            />
          </Box>
          <Box flex={1}>
            <Field component={SelectField} name="ampm" options={AMPM_OPTIONS} />
          </Box>
        </Flex>

        <Box py={20}>
          <Button
            variant="containedPrimary"
            disabled={!isValid}
            loading={isSubmitting}
            loadingText={<FormattedMessage id="saving" />}
            type="submit"
          >
            {buttonText}
          </Button>
        </Box>
      </FluidContainer>
    );
  };

  render() {
    const { initialValues } = this.props;
    const start = moment().startOf('day');
    const diff = moment().diff(start, 'minute');

    return (
      <Formik
        initialValues={{
          ...convertToAmpmHour(initialValues.reminderHour || diff),
          day: initialValues.reminderDay || moment().day()
        }}
        onSubmit={this.handleSubmit}
        validationSchema={schema}
        isInitialValid
      >
        {this.renderForm}
      </Formik>
    );
  }
}

ReminderForm.propTypes = {
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  initialValues: PropTypes.object
};

export default ReminderForm;
