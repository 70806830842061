import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Box } from 'rebass';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, FluidContainer } from 'components/common';
import { AppSelectors } from 'redux/AppRedux';
import firebase from 'services/firebase';
import PrivacyPolicyModal from 'containers/privacy/PrivacyPolicyModal';
import moment from 'moment';
import confirm from 'utils/modal';
import pkg from '../../../../package.json';

function PatientInfo({ onBack, onEditReminder }) {
  const [open, setOpen] = useState(false);
  const patient = useSelector(AppSelectors.selectUser);
  const start = moment().startOf('day');
  const diff = moment().diff(start, 'minute');

  const handleLogout = async () => {
    firebase.logEvent('eh_button_click', {event_category: 'main_menu', event_action: 'modal', event_label: 'Log Out'})

    try {
      firebase.logEvent('eh_screen_view', {event_category: 'logout'});
      await confirm({
        title: <FormattedMessage id="are_you_sure_log_out" />,
        okLabel: <FormattedMessage id="log_out" />,
        cancelLabel: <FormattedMessage id="cancel" />
      });
      onBack();
      firebase.logEvent('eh_button_click', {event_category: 'logout', event_action: 'exit', event_label: 'Log Out'})

      await firebase.auth().signOut();
    } catch (e) {
      // do nothing
    }
  };

  return (
    <FluidContainer>
      <Box variant="infoCard" mb={20}>
        <Text>
          <FormattedMessage id="name" />
          &nbsp;{patient.name} {patient.lastName}
        </Text>
        {/* <Text>Email: {patient.email}</Text> */}
        <Text>
          <FormattedMessage id="mrn" />
          &nbsp;{patient.mrn}
        </Text>
        <Text>
          <FormattedMessage id="birth_year" />
          &nbsp;{patient.birthyear}
        </Text>
        <Text>
          <FormattedMessage id="gender" />
          &nbsp;{patient.gender}
        </Text>
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button variant="text" color="text1" onClick={handleLogout}>
            <FormattedMessage id="log_out" />
          </Button>
        </Flex>
      </Box>
      <Box variant="infoCard" mb={20}>
        <Text>
          Reminder Time:{' '}
          {moment()
            .day(patient.reminderDay || moment().day())
            .format('ddd')}
          ,{' '}
          {moment()
            .startOf('day')
            .add(patient.reminderHour || diff, 'minute')
            .format('hh:mm A')}
        </Text>
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button variant="text" color="text1" onClick={onEditReminder}>
            Edit Reminder Time
          </Button>
        </Flex>
      </Box>
      <Box variant="infoCard" mb={20}>
        <Text>
          <FormattedMessage id="ellipsis_health_app_version" />
          &nbsp;{pkg.version}
        </Text>
        <Flex flexDirection="row" justifyContent="flex-end">
          <Button variant="text" color="text1" onClick={() => setOpen(true)}>
            <FormattedMessage id="view_privacy_policy" />
          </Button>
        </Flex>
      </Box>
      <Box variant="infoCard">
        <Flex flexDirection="row" justifyContent="center">
          <Button
            variant="text"
            color="text1"
            as="a"
            target="_blank"
            href="mailto:support@ellipsishealth.com"
          >
            <FormattedMessage id="contact_ellipsis_health" />
          </Button>
        </Flex>
      </Box>
      <PrivacyPolicyModal isOpen={open} onClose={() => setOpen(false)} />
    </FluidContainer>
  );
}

PatientInfo.propTypes = {
  onBack: PropTypes.func,
  onEditReminder: PropTypes.func
};

export default PatientInfo;
