import React from 'react';
import PropTypes from 'prop-types';
import { Button as RebassButton } from 'rebass';

function Button({ loading, disabled, children, loadingText, ...rest }) {
  return (
    <RebassButton disabled={loading || disabled} {...rest}>
      {loading ? loadingText : children}
    </RebassButton>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.node,
  children: PropTypes.node
};

Button.defaultProps = {
  loadingText: 'Loading...'
};

export default Button;
