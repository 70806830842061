import React from 'react';
import { Flex, Image, Text } from 'rebass';
import { Logo } from 'components/common';

function Splash() {
  return (
    <>
      <Flex pt={30} flexDirection="column" alignItems="center">
        <Logo />
      </Flex>
      <Text textAlign="center" variant="logoTitle">
        ellipsis<br />health
      </Text>
      <Flex flexDirection="column" alignItems="center">
        <Text p={40} textAlign="center" variant="sectionTitle" fontSize={6} lineHeight={'1.2em'}>
          Rising<br />Higher
        </Text>
        <Image
          src={`${process.env.PUBLIC_URL}/MC-logo.svg`}
          width={'90%'}
          my={40}
          pl={20}
          py={10}
          style={{ background: 'white' }}
        />
      </Flex>
    </>
  );
}

export default Splash;
