import firebase from 'services/firebase';
import logger from './logger';

function getCollection() {
  return firebase.firestore().collection('sessionStore');
}

function getUserSessions(uid) {
  const collection = getCollection();

  return collection
    .where('uid', '==', uid)
    .get()
    .then(snapshots => {
      const data = [];

      snapshots.forEach(s => {
        data.push(s.data());
      });

      return data.sort(
        (a, b) => b.startTimestamp.seconds - a.startTimestamp.seconds
      );
    });
}

function getFileName(sessionId, questionId, recordsCount) {
  return `${sessionId}_elip_${questionId}_part_${recordsCount}.wav`;
}

function storeRecording(
  uid,
  sessionId,
  questionId,
  recordsCount,
  blob,
  duration
) {
  const fileName = getFileName(sessionId, questionId, recordsCount);
  const storageRef = firebase
    .storage()
    .ref()
    .child('sessions')
    .child(uid)
    .child(sessionId)
    .child(fileName);

  const metadata = {
    contentType: 'audio/wav',
    customMetadata: {
      uid,
      sessionID: sessionId,
      questionID: questionId,
      duration: duration ? `${duration}` : null
    }
  };

  console.log('File meta', metadata);

  return storageRef.put(blob, metadata);
}

function create(data) {
  const collection = getCollection();
  const doc = collection.doc();

  logger.log('Creating session', data);

  return doc.set(data).then(() => doc.id);
}

function update(sessionId, data) {
  const collection = getCollection();
  const doc = collection.doc(sessionId);

  logger.log('Updating session', sessionId, data);

  return doc.update(data);
}

function loadScore(sessionId) {
  const collection = getCollection();
  const doc = collection.doc(sessionId);

  logger.log('Loading score', sessionId);

  return new Promise((resolve, reject) => {
    doc.onSnapshot(d => {
      const data = d.data();
      logger.log('Session Loaded', sessionId, data);
      if (data) {
        resolve(data);
      }
    }, reject);
  });
}

export default {
  getUserSessions,
  storeRecording,
  create,
  update,
  loadScore
};
