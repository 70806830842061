import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Flex, Box, Text } from 'rebass';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { colors } from 'styles/theme';
import recordBackground from 'assets/icons/record-button.svg';

import 'react-circular-progressbar/dist/styles.css';

function SoundSpectrum({
  progress,
  text,
  onClick,
  recording,
  disabled,
  ...rest
}) {
  return (
    <Box sx={{ position: 'relative', width: 'recorder', height: 'recorder' }}>
      <Box
        sx={{
          width: 'recorderProgress',
          height: 'recorderProgress',
          position: 'absolute',
          top: -2,
          left: -2
        }}
        onClick={disabled ? noop : onClick}
      >
        <CircularProgressbar
          value={progress}
          strokeWidth={4}
          styles={buildStyles({
            pathColor: colors.progressbar,
            trailColor: 'transparent'
          })}
        />
      </Box>
      <Flex
        variant="recorder"
        bg={recording ? 'navyBlue' : 'transparent'}
        sx={recording ? {} : {
            backgroundImage: `url(${recordBackground})`,
            backgroundPositionX: '-6px',
            backgroundPositionY: '0px',
            backgroundSize: '112px'
        }}
        onClick={onClick}
        {...rest}
      >
        <Box id="recordSpectrum" variant="recordSpectrum" mt={35} mb={0} sx={{visibility: recording? 'visible' : 'hidden' }}/>
        <Text variant="recorderTitle" color={disabled ? 'gray2' : 'white'}>
          {text}
        </Text>
      </Flex>
    </Box>
  );
}

SoundSpectrum.propTypes = {
  progress: PropTypes.number,
  recording: PropTypes.bool,
  disabled: PropTypes.bool,
  text: PropTypes.node,
  onClick: PropTypes.func
};

export default SoundSpectrum;
