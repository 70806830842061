import firebase from 'services/firebase';

function getCollection() {
  return firebase.firestore().collection('partners');
}

function get(partnerId) {
  const collection = getCollection();

  return collection
    .doc(partnerId)
    .get()
    .then(doc => doc.data());
}

function getClinicians(partner) {
  const func = firebase
    .functions()
    .httpsCallable('dashboardFunctions-getClinicians');
  return func(partner).then(result => {
    if (result.data) {
      return result.data.map(d => ({
        value: d.id,
        label: `${d.firstName} ${d.lastName}`
      }));
    }

    return [];
  });
}

function list() {
  return getCollection()
    .get()
    .then(snapshots => {
      const items = [];
      snapshots.forEach(s => {
        items.push(s.data());
      });
      return items;
    });
}

export default {
  get,
  list,
  getClinicians
};
