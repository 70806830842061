import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MAIN_INTRO from 'constants/main-intro';
import firebase from 'services/firebase';
import { ReactComponent as ShieldIcon } from 'assets/icons/shield.svg';
import Intro from './Intro';

function MainIntro({ onNext }) {
  const [step, setStep] = useState(0);
  const handleNext = async () => {
    if (step < MAIN_INTRO.length - 1) {
      setStep(step + 1);
    } else {
      firebase.logEvent('eh_button_click', {event_category: 'welcome', event_action: 'exit', event_label: 'Got it'})
      onNext();
    }
  };

  const pageNumText = `Introduction ${step + 1} of ${MAIN_INTRO.length}.`
  const icon = (step === MAIN_INTRO.length - 1) ? ShieldIcon : null;
  return (
    <Intro
      title={MAIN_INTRO[step].title}
      description={MAIN_INTRO[step].text}
      buttonText={MAIN_INTRO[step].button}
      pageCounter={pageNumText}
      onSubmit={handleNext}
      icon={icon}
    />
  );
}

MainIntro.propTypes = {
  onNext: PropTypes.func
};

export default MainIntro;
