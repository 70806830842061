const INTERVAL = 300;

class Timer {
  constructor(onTime) {
    this.onTime = onTime;
  }

  start = () => {
    this.startTime = new Date();
    this.interval = setInterval(() => {
      const passedTime =
        (new Date().getTime() - this.startTime.getTime()) / 1000;
      this.onTime(passedTime);
    }, INTERVAL);
  };

  stop = () => {
    clearInterval(this.interval);
  };
}

export default Timer;
