const BUFFER_SIZE = 8192;
const SAMPLE_RATE = 16000;

class AudioRecorder {
  constructor(audioContext, audioStream, audioSource, onFinish = () => {}) {
    this.audioContext = audioContext;
    this.audioStream = audioStream;
    this.audioSource = audioSource;
    this.audioNode = this.createAudioNode(audioContext);
    this.onFinish = onFinish;
  }

  start = () => {
    this.incrementBy = this.audioContext.sampleRate / SAMPLE_RATE;
    this.recordedData = [];

    this.recordingLength = 0;
    this.duration = 0;
    this.segmentStart = new Date();

    this.audioNode.connect(this.audioContext.destination);
    this.audioSource.connect(this.audioNode);
    this.audioNode.onaudioprocess = e => this.onAudioProcess(e);

    this.recording = true;
    this.startDate = new Date();
  };

  stop = () => {
    this.recording = false;

    this.audioStream.getTracks().forEach(track => {
      track.stop();
    });
    this.audioNode.disconnect();

    // Create the new wav file
    const view = this.createWav(this.recordedData, this.recordingLength);
    const blob = new Blob([view], { type: 'audio/wav' });

    this.onFinish({
      duration: this.duration,
      blob
    });
  };

  createAudioNode = audioContext => {
    if (audioContext.createJavaScriptNode) {
      return audioContext.createJavaScriptNode(BUFFER_SIZE, 1, 1);
    }

    if (audioContext.createScriptProcessor) {
      return audioContext.createScriptProcessor(BUFFER_SIZE, 1, 1);
    }

    throw new Error('WebAudio is not supported!');
  };

  float32_To_int16(buffer, incrementBy) {
    const len = Math.floor(buffer.length / incrementBy);
    // e.g. 8 bit = 128 16 bit = 32768
    const scale = 2 ** 16 * 0.75;
    const newBuf = new Int16Array(len);
    let offset = 0;
    // incrementBy may not be an int so need to Math.floor it (e.g. source sample rate is 44kHz / 16kHz = 2.75)
    for (let i = 0; i < buffer.length; i += incrementBy) {
      newBuf[offset] = Math.floor(buffer[Math.floor(i)] * scale);
      offset += 1;
    }
    return newBuf;
  }

  writeUTFBytes(view, offset, str) {
    for (let i = 0; i < str.length; i += 1) {
      view.setUint8(offset + i, str.charCodeAt(i));
    }
  }

  createWav(data, dataLength) {
    const buffer = new ArrayBuffer(44 + dataLength * 2);
    const view = new DataView(buffer);

    this.writeUTFBytes(view, 0, 'RIFF'); // RIFF chunk descriptor/identifier
    view.setUint32(4, 44 + dataLength * 2, true); // RIFF chunk length
    this.writeUTFBytes(view, 8, 'WAVE'); // RIFF type
    this.writeUTFBytes(view, 12, 'fmt '); // format chunk identifier, FMT sub-chunk
    view.setUint32(16, 16, true); // format chunk length
    view.setUint16(20, 1, true); // sample format (raw)
    view.setUint16(22, 1, true); // mono (1 channel)
    view.setUint32(24, SAMPLE_RATE, true); // sample rate
    view.setUint32(28, SAMPLE_RATE * 2, true); // byte rate (sample rate * block align)
    view.setUint16(32, 2, true); // block align (channel count * bytes per sample)
    view.setUint16(34, 16, true); // bits per sample
    this.writeUTFBytes(view, 36, 'data'); // data sub-chunk identifier
    view.setUint32(40, dataLength * 2, true); // data chunk length

    let index = 44;
    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data[i].length; j += 1) {
        view.setInt16(index, data[i][j], true);
        index += 2;
      }
    }
    return view;
  }

  onAudioProcess = e => {
    const buff = this.float32_To_int16(
      e.inputBuffer.getChannelData(0),
      this.incrementBy
    );

    this.recordedData.push(buff);
    this.recordingLength += buff.byteLength / 2;

    this.duration = (new Date().getTime() - this.startDate.getTime()) / 1000;
  };
}

export default AudioRecorder;
