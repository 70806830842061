import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Text, Card, Link, Box } from 'rebass';
import { Button, FluidContainer, Icon } from 'components/common';
import { AppSelectors } from 'redux/AppRedux';
import RecordingActions, { RecordingSelectors } from 'redux/RecordingRedux';
import { FormattedMessage } from 'react-intl';
import firebase from 'services/firebase';
import { ReactComponent as SmsIcon } from 'assets/icons/sms.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import SwipeableViews from 'react-swipeable-views';

import { ReactComponent as Resource1 } from 'assets/client/resource1.svg';
import { ReactComponent as Resource2 } from 'assets/client/resource2.svg';
import { ReactComponent as Resource3 } from 'assets/client/resource3.svg';
import { ReactComponent as Resource4 } from 'assets/client/resource4.svg';

const RESOURCES = [
  {svg: Resource1, url: 'https://www.menlo.edu/student-life/mental-health-services/'},
  {svg: Resource2, url: 'https://www.7cups.com/'},
  {svg: Resource3, url: 'https://www.youtube.com/watch?v=r8gQoOHPpf4'},
  {svg: Resource4, url: 'https://www.smchealth.org/bhrsservices'}
];

function AssessmentResult({ onExit, showInfo }) {
  const [index, setIndex] = useState(false);
  const [emailed, setEmailed] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(AppSelectors.selectUser);
  const error = useSelector(RecordingSelectors.selectResultError);
  const loading = useSelector(RecordingSelectors.selectResultLoading);
  const session = useSelector(RecordingSelectors.selectSessionResult);

  const handleExit = () => {
    firebase.logEvent('eh_button_click', {event_category: 'session_complete', event_action: 'exit', event_label: 'Back to Home'})
    onExit();
  }

  const emailButtonText = emailed? "Results sent to your email" : "Send results to my email (no one will see your results unless you forward to them)"
  const emailResults = () => {
    if (emailed) return;

    const payload = {
      name: user.name,
      addresses: user.email,
      phq: session.phqScore,
      gad: session.gadScore
    }
    firebase.functions().httpsCallable('emailSessionResults')(payload);
    setEmailed(true)
  }

  setTimeout(() => { handleExit() }, 60000*10);

  useEffect(() => {
    dispatch(RecordingActions.loadSessionResult());
  }, []);

  if (error) {
    return (
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <Text variant="sectionTitle">{error}</Text>
      </Flex>
    );
  }

  if (loading || !session) {
    return (
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <Text variant="sectionTitle">
          <FormattedMessage id="processing_result" />
        </Text>
      </Flex>
    );
  }

  const mouseDownCoords = e => {
    window.checkForDrag = e.clientX;
  };

  const clickOrDrag = (e, url) => {
    const mouseUp = e.clientX;
    if(mouseUp < window.checkForDrag + 6 && mouseUp > window.checkForDrag - 6) {
      window.open(url, "_blank")
    }
  };

  return (
    <>
      <FluidContainer flex={1} px={0}>
        <Box py={20} sx={{background: '#384E78', padding:20, width:'100%', height:'200px'}}> </Box>
        <Box mt={-200}>
          <Text  variant="pageHeading" sx={{textAlign: 'center'}}>Results</Text>
          <Card variant='resultsCard'>
            <Flex>
              <Box p={3} width={1/2}>
                <Text sx={{textAlign: 'center', fontSize:3}}>
                  Depression<br/>Score
                </Text>
                <Text variant='resultNumbers'>
                  {session.phqScore}
                </Text>
              </Box>
              <Box p={3} width={1/2}>
                <Text sx={{textAlign: 'center', fontSize:3}}>
                  Anxiety<br/>Score
                </Text>
                <Text variant='resultNumbers'>
                  {session.gadScore}
                </Text>
              </Box>
            </Flex>
            <Card variant='resultsLinkCard' sx={{position: 'relative'}} onClick={showInfo}>
              <Button variant="resultsHelp">
                &gt;
              </Button>
              What do my scores mean?
            </Card>
            <Card variant='resultsLinkCard' sx={{position: 'relative'}} onClick={emailResults}>
              { !emailed &&
                <Button variant="resultsHelp">
                  &gt;
                </Button>
              }
              {emailButtonText}
            </Card>
          </Card>
        </Box>
        <Text sx={{textAlign: 'left', color: 'black', fontSize:4, padding:'16px 0 0 20px', marginBottom: 0}}>
          Resources
        </Text>
        <Box sx={{overflowX: 'hidden'}}>
          <Box width={140}>
            <SwipeableViews
              enableMouseEvents
              index={index}
              onChangeIndex={setIndex}
              style={{overflowX: 'visible', marginTop:0, marginBottom:0, padding:0}}
            >
              {RESOURCES.map((q, i) => (
                <Box v key={i} variant="resourceCard" onMouseDown={e => mouseDownCoords(e)} onMouseUp={e => clickOrDrag(e, q.url)} sx={{overflow: 'hidden'}} >
                  <Icon as="span" svg={q.svg} size={130} />
                </Box>
              ))}
            </SwipeableViews>
          </Box>
          <Box variant="dotlist" mb={10}>
            {RESOURCES.map((q, i) => (
              <Box
                key={`dot_${i}`}
                variant={index === i ? 'dotselected' : 'dot'}
                onClick={() => setIndex(i)}
              />
            ))}
          </Box>
        </Box>
      </FluidContainer>
      <FluidContainer mb={20}>
        <Flex>
          <Box p={3} width={1/2}>
            <Text sx={{textAlign: 'left', color: 'black', fontSize:4}}>
              In a Crisis?
            </Text>
            <Text mb={10}>
              Call National Suicide Hotline, 24 hours a day to speak to a crisis counselor.
            </Text>
          </Box>
          <Box p={3} width={1/2}>
            <Link href="tel:+18002738255" variant="clickToCall">
              <Button variant='resultsHotlineButton' sx={{position: 'relative'}}>
                <Button variant="resultsHelp" sx={{border: 'none', top:-2, right: 0}}>
                  <Icon as="span" svg={PhoneIcon} size={12} color="black" />
                </Button>
                <i>1-800-273-8255&nbsp;&nbsp;&nbsp;&nbsp;</i>
              </Button>
            </Link>
            <Text sx={{textAlign: 'center'}}>
            or
            </Text>
            <Link href="sms:741741" variant="clickToCall">
              <Button variant='resultsHotlineButton' sx={{position: 'relative'}}>
                <Button variant="resultsHelp" sx={{border: 'none', top:-2, right: 0}}>
                  <Icon as="span" svg={SmsIcon} size={14} color="black" />
                </Button>
                <i>Text to 741741&nbsp;&nbsp;&nbsp;&nbsp;</i>
              </Button>
            </Link>
          </Box>
        </Flex>
        <Button
          variant="containedPrimary"
          onClick={handleExit}
        >
          Exit and Logout
        </Button>
      </FluidContainer>
    </>
  );
}

AssessmentResult.propTypes = {
  showInfo: PropTypes.func,
  onExit: PropTypes.func
};

export default AssessmentResult;
