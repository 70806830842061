import React from 'react';
import { FormattedMessage } from 'react-intl';

export default [
  {
    title: <FormattedMessage id="intro1_title" />,
    text: <FormattedMessage id="intro1_text" />,
    button: <FormattedMessage id="intro1_button" />
  },
  {
    title: '',
    text: <FormattedMessage id="intro2_text" />,
    button: <FormattedMessage id="intro2_button" />
  }
];
