import firebase from './firebase';

async function load(partner) {
  const questionList = firebase.functions().httpsCallable('questionList');
  const result = await questionList({ partner: partner.id });

  const open = Object.values(result.data.open).map(topicData => ({
    id: topicData.categoryId,
    label: topicData.categoryName,
    openEndedQuestions: topicData.questions.map(q => ({
      ...q,
      id: q.questionId,
      prompt_en: q.prompt
    }))
  }));

  const custom = partner.surveys
    // filter out the survey for which questions are not present
    .filter((survey) => result.data.custom[survey])
    .map((survey) => result.data.custom[survey]);

  return {
    open,
    custom,
    phq: (result.data.phq || []).sort((a, b) => a.order - b.order),
    gad: (result.data.gad || []).sort((a, b) => a.order - b.order),
  };
}

export default {
  load
};
