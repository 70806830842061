import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import IdleTimer from 'react-idle-timer'

import SolidHeader from 'containers/layout/SolidHeader';
import Splash from 'containers/layout/Splash';
import Header from 'containers/layout/Header';
import MainIntro from 'containers/intro/MainIntro';
import SessionIntro from 'containers/intro/SessionIntro';
import PatientForm from 'containers/patient/PatientForm';
import PatientInfo from 'containers/patient/PatientInfo';
import ReminderForm from 'containers/patient/ReminderForm';
import SoundRecorder from 'containers/recording/SoundRecorder';
import OrientationModal from 'containers/recording/OrientationModal';
import Questionnaire from 'containers/questionnaire';
import AssessmentResult from 'containers/result/AssessmentResult';
import LogOut from 'containers/result/LogOut';
import { FluidContainer } from 'components/common';
import AppActions, { AppSelectors } from 'redux/AppRedux';
import { FormattedMessage } from 'react-intl';
import ResultsInformationModal from 'containers/result/ResultsInformationModal';

import logger from 'services/logger';
import firebase from 'services/firebase';
import userService from 'services/user';
import partnerService from 'services/partner';
import openQuestionService from 'services/openQuestion';

import { Card, Button, Box, Text } from 'rebass';
import * as STAGES from 'constants/stages';
import Auth from './Auth';

class App extends Component {
  constructor() {
    super();
    this.state = {
      splashed: false,
      main: false,
      verifiedInfo: null,
      orientation: true,
      reminder: false,
      hasLoggedInNow: false,
    };
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  handleOnIdle (event) {
    const { step, user } = this.props;

    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    
    if(user && step !== STAGES.INTRO)  
      this.handleLogout()
  }

  async componentDidMount() {
    const { startup } = this.props;
    setTimeout(() => {this.setState({splashed: true})}, 3000)

    firebase.auth().onAuthStateChanged(user => {
      const { hasLoggedInNow } = this.state;

      if (user) {
        logger.log('User info', user);
        startup(user, hasLoggedInNow);
      } else {
        startup();
      }
    });
  }

  handleMenu = value => {
    if(value)
      firebase.logEvent('eh_button_click', {event_category: 'home', event_action: 'exit', event_label: 'Hamburger Icon'})
    else
      firebase.logEvent('eh_button_click', {event_category: 'main_menu', event_action: 'exit', event_label: 'Back'})

    this.setState({ main: value })
  }

  handleExitSurvey = () => {
    firebase.logEvent('eh_button_click', {event_category: 'active_survey', event_action: 'exit', event_label: 'Exit Survey'})

    const { setStep } = this.props;
    setStep(STAGES.RESULTS)
  }

  handleActivate = async code => {
    const { setPartner, loadClinicians, user } = this.props;

    const verifiedInfo = { uid: user.uid, mrn: '', partnerId: 'MENLO', activationCode: code, contactPhone: '' };
    const partner = await partnerService.get(verifiedInfo.partnerId);

    loadClinicians(verifiedInfo.partnerId);
    setPartner(partner);
    this.setState({ verifiedInfo });
  };

  savePatient = async userInfo => {
    const {
      setStep,
      setUser,
      setTopics,
      partner,
      user,
      newComer,
      setGadQuestions,
      setPhqQuestions,
      setCustomQuestions
    } = this.props;

    const partnerId = partner.id;

    try {
      const resp = await openQuestionService.load(partner);
      setTopics(resp.open);
      setGadQuestions(resp.gad);
      setPhqQuestions(resp.phq);
      setCustomQuestions(resp.custom);
    } catch (e) {
      logger.error(e);
      return Promise.reject(new Error('Open-ended questions not found'));
    }

    if (newComer) {
      await userService.create(user.uid, {
        ...userInfo,

        contactPhone: user.phoneNumber,

        // calling this api means user already accepted terms
        agreePrivacy: true,
        agreeTerms: true,
        consent: true,
        partner: partnerId
      });
    } else {
      await userService.update(user.uid, {
        ...userInfo,
        partner: partnerId
      });
    }

    setUser({
      ...user,
      ...userInfo,
      clinicName: userInfo.partner
    });
    setStep(STAGES.CHECKS);
  };

  saveOrientation = async checked => {
    const { setUser, user } = this.props;

    this.setState({ settingOrientation: true });

    await userService.update(user.uid, {
      skipOrientation: checked
    });

    setUser({
      ...user,
      skipOrientation: true
    });

    this.setState({ orientation: false, settingOrientation: false });
  };

  editReminder = async values => {
    firebase.logEvent('eh_button_click', {event_category: 'edit_reminder_time', event_action: 'exit', event_label: 'Save'})

    const { user, setUser } = this.props;

    await userService.editReminder(values);
    setUser({
      ...user,
      ...values
    });
  };

  handleLogout = async () => {
    const { setStep } = this.props;

    await firebase.auth().signOut();
    setStep(STAGES.LOGOUT)
  }

  renderStep(step) {
    const { setStep, user, sessions, partner } = this.props;
    const {
      main,
      reminder,
      verifiedInfo,
      orientation,
      settingOrientation
    } = this.state;

    if (main) {
      if (reminder) {
        firebase.logEvent('eh_screen_view', {event_category: 'edit_reminder_time'});
        return (
          <Box className="App">
            <SolidHeader onBack={() => this.setState({ reminder: false })}>
              <FormattedMessage id="edit_reminder_time" />
            </SolidHeader>
            <ReminderForm
              initialValues={{
                reminderHour: user.reminderHour,
                reminderDay: user.reminderDay
              }}
              buttonText="Save"
              onBack={() => this.setState({ reminder: false })}
              onSubmit={this.editReminder}
            />
          </Box>
        );
      }

      firebase.logEvent('eh_screen_view', {event_category: 'main_menu'});
      return (
        <Box className="App">
          <SolidHeader onBack={() => this.handleMenu(false)}>
            <FormattedMessage id="main_menu" />
          </SolidHeader>
          <PatientInfo
            onBack={() => this.setState({ main: false })}
            onEditReminder={() => this.setState({ reminder: true })}
          />
        </Box>
      );
    }

    switch (step) {
      case STAGES.INTRO:
        firebase.logEvent('eh_screen_view', {event_category: 'welcome'});
        return (
          <Box className="App">
            <MainIntro onNext={() => setStep(STAGES.LOGIN)} />
          </Box>
        );
      case STAGES.LOGIN:
        firebase.logEvent('eh_screen_view', {event_category: 'account_setup'});
        return (
          <Box className="App">
            <SolidHeader>
              {user.mrn || (verifiedInfo && verifiedInfo.mrn)
                ? 'Confirm Account'
                : 'Set Up Account'}
            </SolidHeader>
            <PatientForm
              formProps={{ flex: 1 }}
              initialValues={{
                ...(verifiedInfo
                  ? {
                    mrn: verifiedInfo.mrn,
                    partner: verifiedInfo.partnerId,
                    activationCode: verifiedInfo.activationCode
                  }
                  : {}),
                partner: 'MENLO',
                ...pick(user, [
                  'name',
                  'lastName',
                  'partner',
                  'assignedAdminId',
                  'activationCode',
                  'mrn',
                  'birthyear',
                  'gender',
                  'email'
                ])
              }}
              consent={user.consent}
              onSubmit={this.savePatient}
            />
          </Box>
        );
      case STAGES.CHECKS:
        firebase.logEvent('eh_screen_view', {event_category: 'home'});
        return (
          <Box className="App">
            <SessionIntro
              sessions={sessions}
              user={user}
              onSubmit={() => setStep(STAGES.RECORD)}
            />
          </Box>
        );
      case STAGES.RECORD:
        return (
          <Box className="App">
            {!user.skipOrientation && (
              <OrientationModal
                isOpen={orientation}
                isLoading={settingOrientation}
                onClose={() => setStep(STAGES.ORIENTATION)}
                onProceed={this.saveOrientation}
              />
            )}
            <SoundRecorder />
          </Box>
        );
      case STAGES.ORIENTATION:
        firebase.logEvent('eh_screen_view', {event_category: 'begin_session'});
        return (
          <Box className="App">
            <Header />
            <FluidContainer flex={1}>
              <Card variant="card2">
                <FormattedMessage
                  id="thanks_for_starting_session"
                  values={{ name: user.name }}
                />
                <br />
                <br />
                <FormattedMessage id="lets_record_your_voice" />
              </Card>
            </FluidContainer>
            <FluidContainer py={20}>
              <Button variant="containedPrimary" onClick={() => setStep(STAGES.RECORD)}>
                <FormattedMessage id="lets_go" />
              </Button>
            </FluidContainer>
          </Box>
        );
      case STAGES.SURVEY:
        firebase.logEvent('eh_screen_view', {event_category: 'active_survey'});
        return (
          <Box className="App">
            <Questionnaire userName={user.name} onFinish={() => setStep(STAGES.RESULTS)} />
          </Box>
        );
      case STAGES.RESULTS:
        firebase.logEvent('eh_screen_view', {event_category: 'session_complete'});
        return (
          <Box className="App">
            <AssessmentResult
              onExit={this.handleLogout}
              showInfo={() => setStep(STAGES.SCORESINFO)}
            />
          </Box>
        );
      case STAGES.SCORESINFO:
        return (
          <Box className="App">
            <ResultsInformationModal
              onClose={() => setStep(STAGES.RESULTS)}
            />
          </Box>
        );
      case STAGES.LOGOUT:
        return (
          <Box className="App">
            <LogOut />
          </Box>
        );
      default:
    }

    return null;
  }

  render() {
    const { loaded, step, user, newComer } = this.props;
    const { verifiedInfo, splashed } = this.state;

    if (!loaded || !splashed) {
      firebase.logEvent('eh_screen_view', {event_category: 'splash'});
      return (
        <Box className="App" variant="splashBg">
          <Splash />
        </Box>
      );
    }

    if (step !== STAGES.INTRO && step !== STAGES.LOGOUT) {
      if (!user) {
        return <Auth onSuccess={() => this.setState({ hasLoggedInNow: true })} />;
      }

      if (newComer && !verifiedInfo) {
        this.handleActivate('mc2020')
      }
    }

    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 10}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {this.renderStep(step)}
      </>
    )
  }
}

App.propTypes = {
  loaded: PropTypes.bool,
  newComer: PropTypes.bool,
  step: PropTypes.number,
  user: PropTypes.object,
  sessions: PropTypes.array,
  setStep: PropTypes.func,
  setTopics: PropTypes.func,
  startup: PropTypes.func,
  setUser: PropTypes.func,
  setPartner: PropTypes.func,
  partner: PropTypes.object,
  loadClinicians: PropTypes.func,
  setGadQuestions: PropTypes.func,
  setPhqQuestions: PropTypes.func,
  setCustomQuestions: PropTypes.func
};

const mapStatesToProps = state => ({
  partner: AppSelectors.selectPartner(state),
  user: AppSelectors.selectUser(state),
  newComer: AppSelectors.selectNewComer(state),
  loaded: AppSelectors.selectLoaded(state),
  step: AppSelectors.selectStep(state),
  sessions: AppSelectors.selectSessions(state)
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(AppActions.setUser(user)),
  startup: (user, hasLoggedInNow) => dispatch(AppActions.startup(user, hasLoggedInNow)),
  setStep: step => dispatch(AppActions.setStep(step)),
  setTopics: topics => dispatch(AppActions.setTopics(topics)),
  setPartner: partner => dispatch(AppActions.setPartner(partner)),
  loadClinicians: partner => dispatch(AppActions.loadClinicians(partner)),
  setGadQuestions: q => dispatch(AppActions.setGadQuestions(q)),
  setPhqQuestions: q => dispatch(AppActions.setPhqQuestions(q)),
  setCustomQuestions: q => dispatch(AppActions.setCustomQuestions(q))
});

export default connect(mapStatesToProps, mapDispatchToProps)(App);
