import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Text } from 'rebass';
import { isMicAllowed } from 'utils/Microphone';
import { AlertPanel, Icon } from 'components/common';
import { getMobileOS } from 'utils/mobileos';
import firebase from 'services/firebase';
import { ReactComponent as MicrophoneIcon } from 'assets/icons/microphone.svg';
import Intro from './Intro';

const SETTINGS = {
  iOS: 'App-prefs://prefs:root=Settings',
  unknown: '#'
};

const os = getMobileOS();

function SessionIntro({ sessions, user, onSubmit }) {
  const [error, setError] = useState(false);
  const handleSubmit = async () => {
    try {
      await isMicAllowed();
      firebase.logEvent('eh_button_click', {event_category: 'home', event_action: 'exit', event_label: 'Start Session'})
      onSubmit();
    } catch (e) {
      setError(true);
      firebase.logEvent('eh_user_error', {event_category: 'home', event_action: 'message', event_label: 'Microphone'})
      console.log(e.name, e.message, e);
    }
  };

  return (
    <Intro
      description={
        <FormattedMessage
          id={
            sessions[0] && !sessions[0].uid
              ? 'you_are_all_set'
              : 'it_is_time_to_record_a_session'
          }
          values={{ name: user.name }}
        />
      }
      buttonText={<FormattedMessage id="start_session" />}
      disabled={error}
      onSubmit={handleSubmit}
      icon={MicrophoneIcon}
    >
      <Text variant="sectionTitle" mt={20}>
        {error && (
          <AlertPanel my={20}>
            Microphone permission is required for recording audio. Please {' '}
            {os === 'iOS' ? (
              <Text as="span">tap on <Text as="span" fontWeight="bold">aA</Text> in the address bar at the top. Go to <Text as="span" fontWeight="bold">Website Settings</Text> and select <Text as="span" fontWeight="bold">Allow</Text> for microphone permission. </Text>
              ) : os === 'Android' ? (
                <Text as="span">tap on the <Text as="span" fontWeight="bold">lock icon</Text> in the address bar at the top. Go to <Text as="span" fontWeight="bold">Site settings</Text> and select <Text as="span" fontWeight="bold">Allow</Text> for microphone permission. </Text>
              ) : (
                <Text as="span">go to <Text as="span" fontWeight="bold">Broswer Settings</Text> and enable microphone permission. </Text>
            )}
            Refresh the page to continue.
          </AlertPanel>
        )}
      </Text>
    </Intro>
  );
}

SessionIntro.propTypes = {
  sessions: PropTypes.array,
  user: PropTypes.object,
  onSubmit: PropTypes.func
};

export default SessionIntro;
