import { createReducer, createActions } from 'reduxsauce';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  reset: [],
  setTopic: ['topic'],
  setQuestionId: ['questionId'],
  setQuestions: ['questions'],
  setSessionId: ['sessionId', 'startTimestamp'],
  setSaving: ['isSaving'],
  setResultLoading: ['isLoadingResult'],
  setFinishing: ['isFinishing'],
  setLastSession: ['session'],

  startRecording: null,
  pauseRecording: null,
  resumeRecording: null,
  finishRecording: ['finishedEarly'],
  saveRecording: ['blob', 'duration'],
  resetRecording: null,

  setDuration: ['questionId', 'duration'],
  loadSessionResult: [],
  setSessionResult: ['sessionResult'],
  setSessionResultError: ['resultError']
});

export const RecordingTypes = Types;

export default Creators;

/* --------------------- Selectors ---------------- */
export const RecordingSelectors = {
  selectTopic: state => state.recording.topic,
  selectQuestionId: state => state.recording.questionId,
  selectQuestions: state => state.recording.questions,
  selectSessionId: state => state.recording.sessionId,
  selectRecording: state => state.recording.isRecording,
  selectLastSession: state => state.recording.lastSession,
  selectSaving: state => state.recording.isSaving,
  selectFinishing: state => state.recording.isFinishing,
  selectRecordCount: state => state.recording.recordCount,
  selectFinishedEarly: state => state.recording.finishedEarly,
  selectStartTimestamp: state => state.recording.startTimestamp,
  selectSessionResult: state => state.recording.sessionResult,
  selectResultLoading: state => state.recording.isLoadingResult,
  selectResultError: state => state.recording.resultError,
  selectAllDurations: state => state.recording.allDurations
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  sessionId: null,
  sessionResult: null,
  recordCount: 0,
  startTimestamp: null,
  lastSession: {},
  topic: null,
  questions: [],
  questionId: '',
  allDurations: {},
  finished: true,
  isRecording: false,
  isFinishing: false,
  isSaving: false,
  isLoadingResult: false,
  finishedEarly: false,
  resultError: null
};

/* ------------------- Reducers --------------------- */
export const setTopic = (state, { topic }) => ({
  ...state,
  topic
});

export const setQuestionId = (state, { questionId }) => ({
  ...state,
  questionId
});

export const setQuestions = (state, { questions }) => ({
  ...state,
  questions
});

export const setSessionId = (state, { sessionId, startTimestamp }) => ({
  ...state,
  sessionId,
  startTimestamp
});

export const startRecording = state => ({
  ...state,
  recordCount: 0,
  isRecording: true,
  startTimestamp: new Date(),
  endTimestamp: null,
  finishedEarly: false,
  finished: false,
  allDurations: {}
});

export const pauseRecording = state => ({
  ...state,
  isRecording: false
});

export const resumeRecording = state => ({
  ...state,
  isRecording: true,
  recordCount: state.recordCount + 1
});

export const finishRecording = (state, { finishedEarly }) => ({
  ...state,
  finishedEarly,
  finished: true,
  isRecording: false
});

export const setSaving = (state, { isSaving }) => ({
  ...state,
  isSaving
});

export const setResultLoading = (state, { isLoadingResult }) => ({
  ...state,
  isLoadingResult
});

export const setSessionResult = (state, { sessionResult }) => ({
  ...state,
  sessionResult
});

export const setSessionResultError = (state, { resultError }) => ({
  ...state,
  resultError
});

export const setDuration = (state, { questionId, duration }) => ({
  ...state,
  allDurations: {
    ...state.allDurations,
    [questionId]: duration
  }
});

export const setFinishing = (state, { isFinishing }) => ({
  ...state,
  isFinishing
});
export const setLastSession = (state, { session }) => ({
  ...state,
  lastSession: {
    ...state.lastSession,
    ...session
  }
});

export const resetRecording = () => ({
  ...INITIAL_STATE
});
export const reset = () => ({ ...INITIAL_STATE });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_TOPIC]: setTopic,
  [Types.SET_QUESTION_ID]: setQuestionId,
  [Types.SET_QUESTIONS]: setQuestions,
  [Types.SET_SESSION_ID]: setSessionId,
  [Types.START_RECORDING]: startRecording,
  [Types.PAUSE_RECORDING]: pauseRecording,
  [Types.RESUME_RECORDING]: resumeRecording,
  [Types.FINISH_RECORDING]: finishRecording,
  [Types.RESET_RECORDING]: resetRecording,
  [Types.SET_SAVING]: setSaving,
  [Types.SET_FINISHING]: setFinishing,
  [Types.SET_DURATION]: setDuration,
  [Types.SET_SESSION_RESULT]: setSessionResult,
  [Types.SET_SESSION_RESULT_ERROR]: setSessionResultError,
  [Types.SET_RESULT_LOADING]: setResultLoading,
  [Types.SET_LAST_SESSION]: setLastSession,
  [Types.RESET]: reset
});
