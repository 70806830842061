import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';
import { FormattedMessage } from 'react-intl';
import { FluidContainer, Icon } from 'components/common';
import { ReactComponent as LeftIcon } from 'assets/icons/chevron-left.svg';

function SolidHeader({ children, onBack, ...rest }) {
  return (
    <FluidContainer variant="solidHeader" mb={20} {...rest}>
      <Flex justifyContent="center" alignItems="center" py={5}>
        <Box flex={1} sx={{ position: 'relative' }}>
          {onBack && (
            <Text variant="headerLink" onClick={onBack}>
              <Icon as="span" svg={LeftIcon} size={18} color="gray5" />
              &nbsp;
              <FormattedMessage id="back" />
            </Text>
          )}
          <Text variant="headerTitle">{children}</Text>
        </Box>
      </Flex>
    </FluidContainer>
  );
}

SolidHeader.propTypes = {
  children: PropTypes.node,
  onBack: PropTypes.func
};

export default SolidHeader;
