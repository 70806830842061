import moment from 'moment';

export function convertToDiff(hour, minute, ampm) {
  let m = moment();
  const start = moment().startOf('day');

  m = m.hour(ampm === 'pm' ? hour + 12 : hour);
  m = m.minute(minute);

  return m.diff(start, 'minute');
}

export function convertToAmpmHour(reminderHour) {
  const m = moment()
    .startOf('day')
    .add(reminderHour, 'minute');

  return {
    hour: m.hour() % 12,
    minute: m.minute() - (m.minute() % 5),
    ampm: m.hour() >= 12 ? 'pm' : 'am'
  };
}
