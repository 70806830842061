import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@rebass/forms';
import Field from './Field';

const SelectField = ({
  field,
  form,
  label,
  sublabel,
  rightIcon,
  helpText,
  labelProps,
  options,
  empty,
  ...otherProps
}) => {
  return (
    <Field
      form={form}
      name={field.name}
      label={label}
      sublabel={sublabel}
      helpText={helpText}
      labelProps={labelProps}
      render={({ hasError }) => (
        <Select
          variant={hasError ? 'invalidInput' : 'input'}
          {...field}
          {...otherProps}
        >
          {empty && <option value="">{empty}</option>}
          {options.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
      )}
    />
  );
};

SelectField.propTypes = {
  options: PropTypes.array,
  field: PropTypes.object,
  form: PropTypes.object,
  rightIcon: PropTypes.node,
  empty: PropTypes.string,
  label: PropTypes.node,
  sublabel: PropTypes.node,
  helpText: PropTypes.node,
  labelProps: PropTypes.object,
  submitCount: PropTypes.number
};

export default SelectField;
