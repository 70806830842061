import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Text, Box } from 'rebass';
import range from 'lodash/range';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  AlertPanel,
  FluidContainer,
  // Icon,
  FullLoading
} from 'components/common';
import { InputField, SelectField } from 'components/formik';
import ConsentModal from 'containers/privacy/ConsentModal';
import { AppSelectors } from 'redux/AppRedux';
// import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import firebase from 'services/firebase';
import schema from './schema';

const INITIAL_VALUES = {
  name: '',
  lastName: '',
  partner: 'MENLO',
  birthyear: '',
  mrn: '',
  gender: 'M',
  email: '',
  assignedAdminId: ''
};

const GENDER_OPTIONS = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'NB', label: 'Non-binary' },
  { value: 'Undisclosed', label: 'Prefer not to disclose' }
];

const year = new Date().getFullYear();
const YEAR_OPTIONS = range(year - 100, year + 1)
  .reverse()
  .map(v => ({
    value: v,
    label: v
  }));
YEAR_OPTIONS.unshift({ value: null, label: 'Select Year' });

class PatientForm extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isOpen: false,
      consent: false
    };
  }

  handleSubmit = async (values, actions) => {
    firebase.logEvent('eh_button_click', {event_category: 'account_setup', event_action: 'exit', event_label: 'Continue'})

    const { onSubmit } = this.props;

    this.setState({ error: null, consent: false });
    try {
      await onSubmit({
        ...values,
        name: values.name.trim(),
        lastName: values.lastName.trim(),
        mrn: values.mrn.trim()
      });
    } catch (e) {
      console.error(e);
      this.setState({ error: e.message });
      actions.setSubmitting(false);
    }
  };

  renderForm = ({ isSubmitting, handleSubmit, isValid }) => {
    const {
      formProps,
      clinicians,
      initialValues,
      partner
    } = this.props;
    const { error, isOpen, consent } = this.state;
    const hasMrn = initialValues.mrn;

    return (
      <FluidContainer flexDirection="column" as={Form} {...formProps}>
        <Text mb={20} fontSize={2}>
          Success! You have joined the Ellipsis Health / Menlo College pilot. Please enter the following information to continue. All fields are required.
        </Text>
        <Box flex={1} fontSize={0}>
          <AlertPanel children={error} />
          <Field
            component={InputField}
            name="name"
            label={<FormattedMessage id="first_name" />}
          />
          <Field
            component={InputField}
            name="lastName"
            label={<FormattedMessage id="last_name" />}
          />
          <Field
            component={SelectField}
            name="birthyear"
            label={<FormattedMessage id="birth_year" />}
            options={YEAR_OPTIONS}
          />
          <Field
            component={SelectField}
            name="gender"
            label={<FormattedMessage id="gender" />}
            options={GENDER_OPTIONS}
          />
        </Box>

        <ConsentModal
          isOpen={consent}
          onClose={() => this.setState({ consent: false })}
          onProceed={() => { handleSubmit(); }}
        />

        <Box py={20}>
          <Button
            variant="containedPrimary"
            disabled={!isValid}
            loading={isSubmitting}
            loadingText={<FormattedMessage id="saving" />}
            onClick={() => this.setState({ consent: true })}
            type="button"
          >
            Continue
          </Button>
        </Box>
        {isSubmitting && (
          <FullLoading>
            <FormattedMessage id="saving" />
          </FullLoading>
        )}
      </FluidContainer>
    );
  };

  render() {
    const { initialValues } = this.props;

    return (
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
          ...initialValues,
          birthyear: initialValues.birthyear
            ? String(initialValues.birthyear)
            : ''
        }}
        onSubmit={this.handleSubmit}
        validationSchema={schema}
        validateOnMount={true}
      >
        {this.renderForm}
      </Formik>
    );
  }
}

PatientForm.propTypes = {
  formProps: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  consent: PropTypes.bool,
  partner: PropTypes.object.isRequired,
  clinicians: PropTypes.array,
  initialValues: PropTypes.object
};

const mapStatesToProps = state => ({
  clinicians: AppSelectors.selectClinicians(state),
  partner: AppSelectors.selectPartner(state)
});

export default connect(mapStatesToProps)(PatientForm);
