import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import firebase from 'services/firebase';
import PhoneLoginForm from 'containers/auth/PhoneLoginForm';
import CodeForm from 'containers/auth/CodeForm';
import StartOverForm from 'containers/auth/StartOverForm';

function Auth({ onSuccess}) {
  const [state, setState] = useState({step: 0, option: 0, phone: '', error: ''})
  const [disabled, setDisabled] = useState(false);

  const handlePhoneSubmit = (phone, option, token) => {
    firebase.logEvent('eh_button_click', {event_category: 'verify_phone', event_action: 'exit', event_label: 'Verify Phone'})

    const sendCode = firebase.functions().httpsCallable('initiateCustomAuth');
    return sendCode({ phone, method: "EMAIL", token, partner: 'MENLO' })
      .then((result) => {
        if (result.data.errCode && result.data.errCode === 'WAIT') {
          if(state.step === 0)
            setState({step: 1, phone, option, error: ''})
          else
            setState({...state, phone, option, error: result.data.message})
          console.log(result.data.message)
        } else if (result.data.errCode && result.data.errCode === 'BLOCK') {
          setState({step: 2, phone, option, error: result.data.message})
          console.log(result.data.message)
        } else if (result.data.errCode && result.data.errCode === 'reCAPTCHA') {
          setState({step: 2, phone, option, error: result.data.message})
          console.log(result.data.message)
        } else if (result.data.errCode && result.data.errCode === 'INVALID') {
          setState({step: 2, phone, option, error: 'Invalid email address.\n\nPlease use email associated with @menlo.edu address.'})
          console.log(result.data.message)
        } else {
          setState({step: 1, phone, option, error: ''})
        }
      })
      .catch((error) => {
        console.log(error.message || error)
        setState({step: 1, phone, option, error: ''})
      });
  };

  const handleCodeSubmit = code => {
    firebase.logEvent('eh_button_click', {event_category: 'verify_code', event_action: 'exit', event_label: 'Verify Phone'})

    const verifyCode = firebase.functions().httpsCallable('verifyCustomAuthCode');
    return verifyCode({ phone: state.phone, code, partner: 'MENLO', activationCode: 'mc2020' })
      .then(result => {
        if(!result.data.success) {
          if (result.data.errCode && result.data.errCode === 'BLOCK')
            setState({step: 2, error: result.data.message})
          else
            setState({...state, error: result.data.message})
          console.log(result.data.message)
          return
        }

        return firebase
          .auth()
          .signInWithCustomToken(result.data.token)
          .then((res) => {
            onSuccess(true);
            setDisabled(true);
            return res;
          })
          .catch((error) => {
            console.log(error.message || error)
          })
      })
      .catch((error) => {
        console.log(error.message || error)
        setState({...state, error: 'The code is incorrect or has expired. Please try again.'})
      })
  }

  const resetVerification = () => {
    setState({...state, step: 0, error: ''})
  }

  const clearError = () => {
    if(state.error)
      setState({...state, error: ''})
  }

  if (state.step === 0)
    firebase.logEvent('eh_screen_view', {event_category: 'verify_phone'});
  else
    firebase.logEvent('eh_screen_view', {event_category: 'verify_code'});

  return (
    <Box className="App">
      {state.step === 0 && <PhoneLoginForm onSubmit={handlePhoneSubmit} />}
      {state.step === 1 && (
        <CodeForm
          phone={state.phone}
          option={state.option}
          error={state.error}
          onSubmit={handleCodeSubmit}
          onRetry={handlePhoneSubmit}
          onReset={resetVerification}
          onClear={clearError}
          disabled={disabled}
        />
      )}
      {state.step === 2 && <StartOverForm message={state.error} onSubmit={resetVerification} />}
    </Box>
  );
}

Auth.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default Auth;
