import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

function Icon({ svg, color, size, sx, ...rest }) {
  return (
    <Box
      width={size}
      height={size}
      minWidth={size}
      minHeight={size}
      sx={{
        ...sx,
        display: 'block',
        '& svg': {
          fill: color,
          width: `${size}px`,
          height: `${size}px`
        }
      }}
      {...rest}
    >
      <Box as={svg} />
    </Box>
  );
}

Icon.propTypes = {
  sx: PropTypes.any,
  svg: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.number
};

export default Icon;
