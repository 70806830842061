import React from 'react';
import { Flex } from 'rebass';
import { isMobile } from "react-device-detect";

const FluidContainer = props => {
  const width = (isMobile)?768:360;
  return (
    <Flex
      flexDirection="column"
      maxWidth={width}
      width={[1, width]}
      mx="auto"
      px={20}
      {...props}
    />
  );
};

export default FluidContainer;
