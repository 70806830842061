import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'components/common';
import { FormattedMessage } from 'react-intl';
import { Box, Text } from 'rebass';
import firebase from 'services/firebase';

function PrivacyPolicyModal({ onClose, ...rest }) {
  firebase.logEvent('eh_screen_view', {event_category: 'tos_pp_modal'});

  return (
    <Modal {...rest}>
      <ModalHeader onClose={onClose}>
        <FormattedMessage id="patient_permissions" />
      </ModalHeader>
      <Box width="80vw" p={20}>
        <Box mb={10}>
          <Text
            as="a"
            href="/terms-of-service.pdf"
            variant="link"
            color="brandDark"
            target="_blank"
          >
            <FormattedMessage id="terms_of_service" />
          </Text>
        </Box>
        <Text
          as="a"
          href="/privacy-policy.pdf"
          variant="link"
          color="brandDark"
          target="_blank"
        >
          <FormattedMessage id="privacy_policy" />
        </Text>
      </Box>
    </Modal>
  );
}

PrivacyPolicyModal.propTypes = {
  onClose: PropTypes.func
};

export default PrivacyPolicyModal;
