import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import i18n from './index';

export function getLang() {
  const prevLang = window.localStorage.getItem('lang');
  return prevLang || navigator.language.split(/[-_]/)[0];
}

function TranslationProvider({ children }) {
  const language = getLang();

  return (
    <IntlProvider locale={language} messages={i18n[language] || i18n.en}>
      {children}
    </IntlProvider>
  );
}

TranslationProvider.propTypes = {
  children: PropTypes.node
};

export default TranslationProvider;
