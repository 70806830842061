import React from 'react';
import { Box, Text } from 'rebass';
import { isMobile } from "react-device-detect";

function FullLoading({ children }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
        width: (isMobile)?'100vw':360,
        height: (isMobile)?'100vh':680,
        zIndex: 100,
        bg: 'backdrop',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Text variant="headerTitle">{children}</Text>
    </Box>
  );
}

export default FullLoading;
