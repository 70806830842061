import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Button } from 'rebass';
import { FluidContainer } from 'components/common';

function StartOverForm({ message, onSubmit }) {
  return (
    <>
      <FluidContainer flex={1}>
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          my={80}
          px={20}
          width={1}
        >
          {message.split('\n').map(str => <Text variant="sectionTitle" textAlign="center" pb={12} fontWeight={'normal'}>{str}</Text>)}
        </Flex>
      </FluidContainer>
      <FluidContainer py={20}>
        <Button
          variant="containedPrimary"
          onClick={onSubmit}
        >
          Got it
        </Button>
      </FluidContainer>
    </>
  );
}

StartOverForm.propTypes = {
  message: PropTypes.string,
  onSubmit: PropTypes.func
};

export default StartOverForm;
