import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { ThemeProvider } from 'emotion-theming';
import { Flex, Text } from 'rebass';
import { createConfirmation, confirmable } from 'react-confirm';
import { ModalHeader, Modal, Button } from 'components/common';
import theme from 'styles/theme';

import TranslationProvider from '../i18n/TranslationProvider';

const ConfirmModal = ({
  okLabel,
  cancelLabel,
  title,
  message,
  show,
  proceed,
  dismiss,
  cancel,
  enableEscape
}) => (
  <TranslationProvider>
    <ThemeProvider theme={theme}>
      <Modal
        isOpen={show}
        onRequestClose={dismiss}
        shouldCloseOnEsc={enableEscape}
      >
        {title && <ModalHeader>{title}</ModalHeader>}
        <Text px={10} mb={10}>
          {message}
        </Text>
        <Flex flexDirection="row" justifyContent="center" p={10}>
          <Button variant="text" width="40%" onClick={cancel}>
            {cancelLabel}
          </Button>
          <Button
            variant="containedPrimary"
            width="40%"
            ml={10}
            onClick={proceed}
          >
            {okLabel}
          </Button>
        </Flex>
      </Modal>
    </ThemeProvider>
  </TranslationProvider>
);

ConfirmModal.defaultProps = {
  proceed: noop,
  cancel: noop,
  dismiss: noop,
  okLabel: 'OK',
  cancelLabel: 'Cancel',
  show: false,
  enableEscape: true
};

ConfirmModal.propTypes = {
  proceed: PropTypes.func, // called when ok button is clicked.
  cancel: PropTypes.func, // called when cancel button is clicked.
  dismiss: PropTypes.func, // called when backdrop is clicked or escaped.
  title: PropTypes.node,
  okLabel: PropTypes.node,
  cancelLabel: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  show: PropTypes.bool,
  enableEscape: PropTypes.bool
};

export default createConfirmation(confirmable(ConfirmModal));
