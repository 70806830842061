import range from 'lodash/range';

export const colors = {
  purple: '#8246AF',
  pink: '#A94994',
  blue: '#6D63A7',
  lightBlue: '#a49ac7',
  lighterBlue: '#b5b2d2',
  lightestBlue: '#f7edff',
  metallicBlue: '#384E78',
  black: '#000',
  white: '#fff',
  navyBlue: '#262053',
  lightGray: '#C6C3D3',
  gray: '#7A7D7E',
  gray2: '#979797',
  gray3: '#A9AE86',
  gray4: '#C8C3CF',
  gray5: '#D0D1C9',
  backdrop: '#000000dd',

  cardbg1: 'rgba(130,70,175,0.13)',
  cardbg2: 'rgba(169,73,148,0.23)',

  // alias
  brand: '#3ABAB6',
  brandDark: '#5687D6',
  tertiary: '#81DE80',
  divider: '#979797',
  text1: '#000',
  text2: '#212B66',
  text3: '#C8C3CF',

  progressbar: '#81DE80',

  // outside of theme
  red: '#c6161a',
  orangeRed: '#E03D2C',

  severe: '#cdb6de',
  moderate: '#d9c8e6',
  mild: '#e6daee',
  accent: '#ab4992'
};

export const gradients = {
  primary: `linear-gradient(0deg, ${colors.gray5} 0%, ${colors.brand} 100%)`
};

export const breakpoints = ['768px', '992px', '1200px'];

export const sizes = {
  recorder: 100,
  recorderProgress: 104,
  bigChart: 30,
  smallChart: 20
};

const theme = {
  breakpoints,
  colors,
  fonts: {
    body: '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif'
  },
  fontSizes: [14, 18, 19, 20, 22, 24, 32, 36, 40],
  fontWeights: {
    bold: 700,
    regular: 400
  },
  lineHeights: {
    body: 1.5
  },
  space: range(0, 100),
  radii: {
    lg: 20,
    md: 14,
    xs: 6,
    circle: '50%'
  },
  shadows: {
    primary: '0 3px 25px 0 rgba(255,255,255,0.1)',
    secondary: '0 1px 2px 0 rgba(0,0,0,0.25)'
  },
  sizes,
  text: {
    pageHeading: {
      fontSize: 7,
      color: 'white',
      fontWeight: 'bold'
    },
    title: {
      fontSize: 3,
      fontWeight: 'bold'
    },
    helper: {
      fontSize: 1,
      color: 'black'
    },
    logoTitle: {
      fontSize: 8,
      color: 'gray',
      fontWeight: '200',
      lineHeight: '1em'
    },
    sectionTitle: {
      fontSize: 2,
      color: 'navyBlue',
      fontWeight: 'bold'
    },
    sectionTitleCenter: {
      variant: 'text.sectionTitle',
      textAlign: 'center',
      pb: 5,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'divider'
    },
    topicTitle: {
      fontSize: 2,
      color: 'white',
      fontWeight: 'bold'
    },
    resultNumbers: {
      fontSize: 8,
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    headerLink: {
      fontSize: 2,
      color: 'gray5',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      left: 0,
      top: 2
    },
    mainMenuLink: {
      cursor: 'pointer',
      position: 'absolute',
      left: '5%'
    },
    headerTitle: {
      variant: 'text.title',
      color: 'white',
      textAlign: 'center'
    },
    recorderTitle: {
      fontSize: 0,
      fontWeight: 'bold',
      color: 'white',
      textAlign: 'center',
      textTransform: 'uppercase'
    },
    link: {
      cursor: 'pointer',
      fontSize: 2,
      color: 'black',
      textDecoration: 'underline'
    },
    chartText: {
      color: 'blue',
      textAlign: 'right'
    },
    bigChartText: {
      variant: 'text.chartText',
      fontSize: 2
    },
    smallChartText: {
      variant: 'text.chartText',
      fontSize: 0
    }
  },
  variants: {
    solidHeader: {
      bg: 'brandDark'
    },
    modalHeader: {
      position: 'relative',
      p: 16
    },
    card: {
      borderRadius: 'md',
      borderStyle: 'solid',
      borderWidth: 1,
      fontSize: 2,
      fontWeight: 'bold',
      whiteSpace: 'pre-line'
    },
    card1: {
      variant: 'variants.card',
      p: 25,
      borderColor: 'gray2',
      bg: 'cardbg1',
      color: 'navyBlue',
      textAlign: 'center'
    },
    card2: {
      variant: 'variants.card',
      fontSize: 1,
      fontWeight: 'regular',
      px: 15,
      py: 10,
      borderColor: 'gray2',
      bg: 'cardbg1',
      color: 'navyBlue'
    },
    card_mc: {
      variant: 'variants.card',
      px: 15,
      py: 10,
      border: 'none',
      bg: 'none',
      color: 'navyBlue',
      fontWeight: 'normal',
      textAlign: 'center'
    },
    clickToCall: {
      fontSize: 5,
      color: 'purple',
      textDecoration: 'none'
    },
    infoCard: {
      variant: 'variants.card',
      bg: 'lightBlue',
      color: 'black',
      px: 20,
      py: 10,
      boxShadow: 'secondary',
      border: 'none'
    },
    resultsCard: {
      variant: 'variants.card',
      fontSize: 0,
      bg: 'brandDark',
      color: 'white',
      px: 0,
      py: 0,
      mx: 20,
      boxShadow: 'secondary',
      borderColor: 'white',
      borderRadius: '20px',
      borderWidth: 1,
      borderStyle: 'solid'
    },
    resultsLinkCard: {
      variant: 'variants.resultsCard',
      pl: 20,
      pr: 36,
      py: 10,
      mx: 0,
      border: 'none',
      boxShadow: 'none',
      borderTop: '1px solid white',
      borderRadius: 'none',
      bg: 'transparent',
      color: 'white',
      fontWeight: 'normal'
    },
    resourceCard: {
      variant: 'variants.card',
      border: 'none',
      fontSize: 0,
      height: 130,
      width: 130,
      mx: 10
    },
    topicCard: {
      cursor: 'pointer',
      borderRadius: 'xs',
      bg: 'gray5',
      height: 60,
      p: 10,
      ml: 20,
      mr: 2,
      mb: 5,
      alignItems: 'center',
      transition: 'all .3s ease-in-out',
      boxShadow: 'secondary',
      '& img': {
        height: 40,
        mr: 10
      }
    },
    topicCardSelected: {
      variant: 'variants.topicCard',
      bg: 'tertiary',
      boxShadow: 'secondary'
    },
    recorder: {
      bg: 'navyBlue',
      borderRadius: 'circle',
      width: 'recorder',
      height: 'recorder',
      flexDirection: 'column',
      alignItems: 'center'
    },
    recordSpectrum: {
      width: 70,
      height: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      position: 'relative'
    },
    dotlist: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      mb: 20
    },
    dot: {
      cursor: 'pointer',
      borderRadius: 'circle',
      width: 10,
      height: 10,
      bg: 'navyBlue',
      mr: 10,
      '&:last-of-type': {
        mr: 0
      }
    },
    dotselected: {
      variant: 'variants.dot',
      bg: 'tertiary'
    },
    moodSelect: {
      cursor: 'pointer'
    },
    radioContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      borderRadius: 'circle',
      bg: 'gray5'
    },
    radioDot: {
      width: 16,
      height: 16,
      borderRadius: 'circle',
      bg: 'navyBlue'
    },
    chart: {
      borderStyle: 'solid',
      borderColor: 'divider',
      borderWidth: 1
    },
    chartRow: {
      px: 10,
      '& + &': {
        borderTopStyle: 'solid',
        borderTopColor: 'white',
        borderTopWidth: 1
      }
    },
    bigChartRow: {
      variant: 'variants.chartRow',
      height: 'bigChart'
    },
    smallChartRow: {
      variant: 'variants.chartRow',
      height: 'smallChart'
    },
    chartScore: {
      borderRadius: 'circle',
      mr: 10
    },
    bigChartScore: {
      variant: 'variants.chartScore',
      height: sizes.bigChart - 10,
      width: sizes.bigChart - 10
    },
    smallChartScore: {
      variant: 'variants.chartScore',
      height: sizes.smallChart - 6,
      width: sizes.smallChart - 6
    },
    splashBg: {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      background: 'white'
    },
    infoModalBg: {
      fontSize: 0,
      background: '#F2F2F2'
    },
    surveyWelcomeCard: {
      variant: 'variants.card2',
      bg: 'tertiary',
      color: 'black'
    },
    cardQuestion: {
      variant: 'variants.card2',
      bg: 'white',
      minHeight: 180,
      display: 'flex',
      alignItems: 'center',
    },
  },
  buttons: {
    base: {
      touchAction: 'manipulation',
      outline: 'none',
      transition: 'all .3s ease-in-out',
      userSelect: 'none',
      cursor: 'pointer',
      boxShadow: 'primary'
    },
    contained: {
      touchAction: 'manipulation',
      variant: 'buttons.base',
      borderRadius: 'lg',
      py: 10,
      fontSize: 2,
      '&:disabled': {
        bg: 'lightGray',
        color: 'white',
        cursor: 'not-allowed'
      }
    },
    containedPrimary: {
      variant: 'buttons.contained',
      bg: 'tertiary',
      color: 'black',
      width: '90%',
      ml: '5%',
      boxShadow: 'secondary'
    },
    containedSecondary: {
      variant: 'buttons.contained',
      boxShadow: 'secondary',
      bg: 'white',
      color: 'brand',
      width: '90%',
      ml: '5%'
    },
    text: {
      touchAction: 'manipulation',
      color: 'brandDark',
      bg: 'transparent',
      fontSize: 3,
      fontWeight: 'bold'
    },
    helpButton: {
      touchAction: 'manipulation',
      borderRadius: 'circle',
      width: 24,
      height: 24,
      fontSize: 3,
      fontWeight: 'bold',
      color: 'white',
      bg: 'navyBlue',
      lineHeight: '12px',
      textAlign: 'center',
      position: 'absolute',
      border: '2px solid white',
      top: 10,
      right: 10,
      px: 2,
      py: 2
    },
    resourceButton: {
      variant: 'buttons.helpButton',
      bg: '#704689',
      width: 18,
      height: 18,
      top: 2,
      right: 8,
      fontSize: '10px',
      fontWeight: 'bold',
      paddingTop: 0,
      paddingBottom: 4,
      outline: 'none'
    },
    resultsHelp: {
      variant: 'buttons.text',
      width: 24,
      height: 24,
      lineHeight: '12px',
      textAlign: 'center',
      position: 'absolute',
      color: 'white',
      top: 6,
      right: 10,
      px: 2,
      py: 2
    },
    resultsButton: {
      variant: 'buttons.containedSecondary',
      fontWeight: 'regular',
      py: 5
    },
    resultsHotlineButton: {
      variant: 'buttons.containedSecondary',
      fontWeight: 'bold',
      fontSize: 0,
      color: 'navyBlue',
      borderColor: 'purple',
      borderRadius: 16,
      borderWidth: 2,
      borderStyle: 'solid',
      bg: '#dcc7d7',
      py: 0
    },
    surveyAnswerButton: {
      variant: 'buttons.containedPrimary',
      bg: 'metallicBlue',
      color: 'white',
    }
  },
  forms: {
    input: {
      fontSize: 1,
      color: 'black',
      bg: 'white',
      borderRadius: 0,
      boxSizing: 'border-box',
      borderColor: 'divider',
      borderWidth: 1,
      borderStyle: 'solid',
      px: 15,
      py: 8
    },
    invalidInput: {
      variant: 'forms.input',
      borderColor: 'red'
    },
    label: {
      width: '33.33%',
      minWidth: 100,
      fontSize: 1,
      color: 'black'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      bg: gradients.primary
    }
  }
};

export default theme;
