import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Flex, Text, Box } from 'rebass';
import { Button, AlertPanel, FluidContainer } from 'components/common';
import { InputField } from 'components/formik';
import schema from './schema';
import { Logo } from 'components/common';

const INITIAL_VALUES = {
  phone: ''
};

const GOOGLE_CAPTCHA_KEY = "6LfTBOEZAAAAAEb9DDo_qLC7n1I-0Jq8fMhi8VZc";

class PhoneLoginForm extends Component {
  constructor() {
    super();
    this.state = {
      option: 0,
      waiting: false,
      error: null
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=" + GOOGLE_CAPTCHA_KEY;
    script.async = false;
  
    document.body.appendChild(script);
  }

  validateEmail = value => {
    let errorMessage;
      if ((!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))) {
      errorMessage = 'Invalid email address';
    }
    return errorMessage;
  };

  handleSubmit = async (values, actions) => {
    const { onSubmit } = this.props;
    this.setState({ error: null, waiting: true });
    const { option } = this.state;

    window.grecaptcha.ready(function() {
      window.grecaptcha.execute(GOOGLE_CAPTCHA_KEY, {action: 'emailSignUp'}).then(function(token) {
        try {
          onSubmit(values.email.trim().toLowerCase(), 0, token);
        } catch (e) {
          this.setState({ error: e.message, waiting: false });
          actions.setSubmitting(false);
        }
      });
    });
  };

  renderForm = ({ isSubmitting, isValid }) => {
    const { error, option, waiting } = this.state;
    const disabled = !isValid || waiting;

    return (
      <FluidContainer flexDirection="column" flex={1} as={Form}>
        <Flex p={30} flexDirection="column" alignItems="center">
          <Logo />
        </Flex>
        <Text textAlign="center" fontSize={5} fontWeight={'bold'}>
          Register to Rising Higher
        </Text>
        <Text textAlign="center" p={'10%'} fontSize={2}>
          Let’s register you to Rising Higher.  You will need to use your @menlo.edu email to continue.
        </Text>

        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          my={20}
          width={1}
        >
          <Field
            component={InputField}
            name="email"
            validate={this.validateEmail}
            placeholder="Your @menlo.edu email"
            width='280px'
          />
          { /*
          <Text fontWeight="bold" mb={10} mt={30}>
            Receive Code by:
          </Text>

          <Flex
            key='radio_0'
            name='option'
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            onClick={() => this.setState({ option: 0 })}
            width={1}
            py={10}
            px={20}
          >
            <Flex variant="radioContainer">
              { option === 0 && <Box variant="radioDot" /> }
            </Flex>
            <Text ml={10}>
              Texting me
            </Text>
          </Flex>

          <Flex
            key='radio_1'
            name='option'
            flexDirection="row"
            alignItems="center"
            justifyContent="left"
            onClick={() => this.setState({ option: 1 })}
            width={1}
            py={10}
            px={20}
          >
            <Flex variant="radioContainer">
              { option === 1 && <Box variant="radioDot" /> }
            </Flex>
            <Text ml={10}>
              Calling me
            </Text>
          </Flex>
          */}
          <AlertPanel children={error} my={20} />
        </Flex>

        <Box py={20}>
          <Button
            id="login-button"
            variant="containedPrimary"
            disabled={disabled}
            loading={isSubmitting}
            loadingText="Sending code..."
            type="submit"
          >
            Verify Email
          </Button>
        </Box>
      </FluidContainer>
    );
  };

  render() {
    const { initialValues } = this.props;

    return (
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
          ...initialValues
        }}
        onSubmit={this.handleSubmit}
        validationSchema={schema}
        isInitialValid
      >
        {this.renderForm}
      </Formik>
    );
  }
}

PhoneLoginForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object
};

export default PhoneLoginForm;
