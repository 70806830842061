import firebase from 'services/firebase';
import moment from 'moment';

function getCollection() {
  return firebase.firestore().collection('users');
}

async function verify(activationCode) {
  const verifyActivationCode = firebase
    .functions()
    .httpsCallable('verifyActivationCode');
  const { data } = await verifyActivationCode({ activationCode });
  console.log(data);
  return data;
}

function create(uid, user) {
  const collection = getCollection();

  return collection.doc(uid).update({
    ...user,
    rewardedSessions: 0,
    createdAt: new Date(),
    utcOffset: moment().utcOffset(),
    reminderHour: null,
    assignedAdminId: null,
    deviceData: null,
    token: null,
    nextValidDate: null,
    lastSession: null,
    sessionCount: 0
  });
}

function update(uid, user) {
  const collection = getCollection();

  return collection.doc(uid).update(user);
}

function editReminder({ reminderDay, reminderHour }) {
  const setHour = firebase.functions().httpsCallable('updateReminderTime');
  return setHour({ reminderDay, reminderHour });
}

function get(uid) {
  const collection = getCollection();

  return collection
    .doc(uid)
    .get()
    .then(doc => doc.data());
}

function sendLoginEmail() {
  const callSendLoginEmail = firebase.functions().httpsCallable('sendLoginEmail');
  return callSendLoginEmail();
}

export default {
  get,
  verify,
  editReminder,
  create,
  update,
  sendLoginEmail,
};
