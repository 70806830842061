import React from 'react';
import { Flex, Text, Card } from 'rebass';
import { FluidContainer, Logo } from 'components/common';

function LogOut() {
  return (
    <>
      <FluidContainer flex={1}>
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          my={80}
          px={20}
          width={1}
        >
          <Logo />
          <Card
            variant="card_mc"
            mt={20}
          >
            <Text pb={40} sx={{fontSize: 4, fontWeight: 'bold'}}>
              You have been<br/>logged out!
            </Text>
            Thank you for participating in the Menlo College and Ellipsis Health  emotional health awareness campaign.
          </Card>
        </Flex>
      </FluidContainer>
    </>
  );
}

export default LogOut;
