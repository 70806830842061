import React from 'react';
import { FormattedMessage } from 'react-intl';
// 1) Press Record to begin // Starting state; goes away when the user presses record
// 2) You're doing great! Keep going... // appears at 1 *second* (0%) mark, and goes away with one of the following conditions
// 3) Scroll to speak to more questions... // Nudge condition that appears at 1min mark if the user hasn't scrolled the talking prompts
// 4) Consider picking a new topic now...// Nudge condition that appears at 2.5min mark if the user is still on their first topic

// 5) Good job, keep it up! // Appears at 1 min (25%) mark if the user hasn't triggered & not resolved a nudge condition above
// 6) Great job, halfway done... // Appears at 2min (50%) mark if the user hasn't triggered & not resolved a nudge condition above
// 7) Good job, almost done.. // Appears at 3min (75%) mark if the user hasn't triggered & not resolved a nudge condition above
// 8) All set! Press "Finish" or "Exit Recording" when ready... // Appears at 4min (100%) mark when enough data is captured

// x) Recording will resume after you pick a topic // Appears after the starting state when topic is unselected, and goes away when user selects their second or later topic

export default function getRecordingHelpText(params, timeLimit) {
  const {
    startTimestamp,
    topic,
    topicChangeCount, // number of topics changed
    promptChangeCount, // number of prompts changed in current topic
    recording, // is recording or not
    passedTime, // total passed time
    currentTime // passed time of current recording
  } = params;
  const quarter = timeLimit / 4; // usually 1 min, but configurable per partner
  const halfMin = timeLimit / 6;
  const selectedTopic = !!topic;
  const started = startTimestamp > 0;
  const changedPrompts = promptChangeCount > 1;

  if (started && !selectedTopic) {
    return <FormattedMessage id="recording_will_resume" />; // x
  }

  if (!started) {
    return <FormattedMessage id="press_record_to_begin" />; // 1
  }

  if (started && recording) {
    if (passedTime > 4 * quarter) {
      return <FormattedMessage id="all_set_press_finish" />; // 8
    }

    if (
      (topicChangeCount === 1 && passedTime > 2 * halfMin) ||
      (topicChangeCount <= 2 && passedTime > 4 * halfMin)
    ) {
      return <FormattedMessage id="consider_picking_a_new_topic" />; // 4
    }

    if (!changedPrompts && currentTime > 1 * halfMin) {
      return <FormattedMessage id="scroll_to_speak_to_more_questions" />; // 3
    }

    if (passedTime > 3 * quarter) {
      return <FormattedMessage id="good_job_almost_done" />; // 7
    }

    if (passedTime > 2 * quarter) {
      return <FormattedMessage id="great_job_halfway_done" />; // 6
    }

    if (passedTime > 1 * quarter) {
      return <FormattedMessage id="good_job_keep_it_up" />; // 5
    }

    return <FormattedMessage id="you_are_doing_great_keep_going" />; // 2
  }

  return '';
}
