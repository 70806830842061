import React from 'react';
import ReactModal from 'react-modal';
import theme from 'styles/theme';
import TranslationProvider from '../../../i18n/TranslationProvider';
import { isMobile } from "react-device-detect";

const style = {
  overlay: {
    zIndex: 100,
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 'auto',
    width: (isMobile)?'100vw':360,
    height: (isMobile)?'100vh':680,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, .8)'
  },
  content: {
    position: 'relative',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    left: 'auto',
    maxWidth: '90%',
    borderRadius: theme.radii.xs,
    border: 'none',
    boxShaodw: theme.shadows.secondary,
    padding: '0',
    background: theme.colors.white,
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    overflow: 'visible',
    maxHeight: '90%',
    overflowY: 'auto',
    overflowX: 'hidden'

  }
};

const Modal = ({ ...props }) => (
  <TranslationProvider>
    <ReactModal style={style} ariaHideApp={false} {...props} />
  </TranslationProvider>
);

export default Modal;
