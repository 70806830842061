import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Box } from 'rebass';

function QuestionCarousel({ questions, index, onChangeIndex, ...rest }) {
  return (
    <>
      <SwipeableViews
        enableMouseEvents
        style={{ paddingRight: '20%' }}
        index={index}
        onChangeIndex={onChangeIndex}
        {...rest}
      >
        {questions.map(q => (
          <Box key={q.id} variant="cardQuestion" ml={20} mb={20}>
            {q.prompt}
          </Box>
        ))}
      </SwipeableViews>
      <Box variant="dotlist">
        {questions.map((q, i) => (
          <Box
            key={`dot_${q.id}`}
            variant={index === i ? 'dotselected' : 'dot'}
            onClick={() => onChangeIndex(i)}
          />
        ))}
      </Box>
    </>
  );
}

QuestionCarousel.propTypes = {
  index: PropTypes.number,
  onChangeIndex: PropTypes.func,
  questions: PropTypes.array
};

export default QuestionCarousel;
