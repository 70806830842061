import { call, select, put } from 'redux-saga/effects';
import userService from 'services/user';
import partnerService from 'services/partner';
import sessionService from 'services/session';
import logger from 'services/logger';
import * as STAGES from 'constants/stages';
import AppActions, { AppSelectors } from './AppRedux';

export function* startup(action) {
  const { user, hasLoggedInNow } = action;

  try {
    if (user) {
      const data = yield call(userService.get, user.uid);
      logger.log('Loaded user', user.uid, data);
      yield put(AppActions.setNewComer(!data));

      if (data && data.partner) {
        yield put(AppActions.loadClinicians(data.partner));

        const partnerData = yield call(partnerService.get, data.partner);
        yield put(AppActions.setPartner(partnerData));

        if (hasLoggedInNow) {
          if (
            partnerData.loginActions 
            && partnerData.loginActions.documents
            && partnerData.loginActions.documents[data.loginCounter]
          ) {
            yield call(userService.sendLoginEmail);
          }
        }
      }

      yield put(
        AppActions.setUser({
          uid: user.uid,
          phoneNumber: user.phoneNumber,
          ...data
        })
      );

      const sessions = yield call(sessionService.getUserSessions, user.uid);
      logger.log('Loaded sessions', sessions);
      yield put(AppActions.setSessions(sessions));
    } else {
      yield put(AppActions.setUser(null));
      const step = yield select(AppSelectors.selectStep);
      if (step > 1) yield put(AppActions.setStep(STAGES.LOGIN));
    }
    yield put(AppActions.setLoaded(true));
  } catch (e) {
    logger.error(e);
  }
}

export function* loadClinicians(actions) {
  const { partner } = actions;

  const result = yield call(partnerService.getClinicians, partner);
  yield put(AppActions.setClinicians(result));
}
