import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import sum from 'lodash/sum';
import { AppSelectors } from 'redux/AppRedux';
import RecordingActions, { RecordingSelectors } from 'redux/RecordingRedux';
import sessionService from 'services/session';
import logger from 'services/logger';

import Questionnaire from './Questionnaire';

function PhqGadQuestionnaire({
  userName,
  partner,
  setLastSession,
  sessionId,
  gadQuestions,
  phqQuestions,
  customQuestions,
  finishedEarly,
  onFinish
}) {
  const [questions, setQuestions] = useState([]);

  const handleFinish = async currentResult => {
    if (!currentResult) {
      return onFinish();
    }

    const phq = currentResult
      .filter(r => r.type === 'phq')
      .map(r => r.answer * 1 - 1);
    const gad = currentResult
      .filter(r => r.type === 'gad')
      .map(r => r.answer * 1 - 1);
    const custom = {};
    const customScore = {};

    customQuestions.forEach((cqs) => {
      if (cqs.length === 0) {
        logger.log('Some custom survey doesn\'t have any questions');
        return;
      }

      const customKey = cqs[0].class;

      const customAnswers = currentResult.filter(r => r.type === customKey);
      custom[customKey] = customAnswers.map(r => r.answer * 1);
      customScore[customKey] = customAnswers.length
        ? sum(custom[customKey])
        : null;
    });

    const phqScore = sum(phq);
    const gadScore = sum(gad);

    const status =
      phq.length === 8 || gad.length === 7 ? { status: 'valid' } : {};
    const payload = {
      phq,
      gad,
      ...status,
      phqScore,
      gadScore,
      custom:
        (customQuestions && customQuestions.length > 0)
          ? custom
          : null,
      customScore:
        (customQuestions && customQuestions.length > 0)
          ? customScore
          : null
    };

    await sessionService.update(sessionId, payload);
    setLastSession(payload);

    if (currentResult.length === questions.length) {
      return onFinish();
    }
  };

  useEffect(() => {
    let newQuestions = [];
    if (partner.phq || finishedEarly) {
      newQuestions = [...phqQuestions];
    }

    if (partner.gad || finishedEarly) {
      newQuestions = [...newQuestions, ...gadQuestions];
    }

    if (customQuestions || finishedEarly) {
      customQuestions.forEach((cqs) => {
        newQuestions = [
          ...newQuestions,
          ...cqs.sort((a, b) => a.order - b.order)
        ];
      });
    }

    if (!newQuestions.length) {
      handleFinish();
    }

    setQuestions(newQuestions);
  }, [partner]);

  if (!questions.length) {
    return null;
  }

  return <Questionnaire userName={userName} questions={questions} onSave={handleFinish} />;
}

PhqGadQuestionnaire.propTypes = {
  userName: PropTypes.string,
  finishedEarly: PropTypes.bool,
  onFinish: PropTypes.func,
  setLastSession: PropTypes.func,
  partner: PropTypes.object,
  gadQuestions: PropTypes.array,
  phqQuestions: PropTypes.array,
  customQuestions: PropTypes.array,
  sessionId: PropTypes.string
};

const mapStatesToProps = state => ({
  partner: AppSelectors.selectPartner(state),
  finishedEarly: RecordingSelectors.selectFinishedEarly(state),
  sessionId: RecordingSelectors.selectSessionId(state),
  customQuestions: AppSelectors.selectCustomQuestions(state),
  gadQuestions: AppSelectors.selectGadQuestions(state),
  phqQuestions: AppSelectors.selectPhqQuestions(state)
});

const mapDispatchToProps = dispatch => ({
  setLastSession: session => dispatch(RecordingActions.setLastSession(session))
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(PhqGadQuestionnaire);
