export default {
  // splash
  a_new_vital_sign: 'A new vital sign',
  a_new_world: 'a new world',

  // app
  edit_reminder_time: 'Edit Reminder Time',
  main_menu: 'Main Menu',
  set_up_account: 'Set up account',
  most_recent_session: 'Most Recent Session',
  total_sessions: 'Total Sessions',
  you_are_all_set: 'You’re all set on recordings at the moment, {name}.',
  it_is_time_to_record_a_session:
    'Hi {name}, before we begin, please disconnect any Bluetooth headset/microphone and use the local microphone.\n\nThen, press the button below to get started with your recording...',
  start_session: 'Start Session',
  thanks_for_starting_session: `Hi {name}, thanks for starting a session!`,
  lets_record_your_voice:
    'Let’s record your voice to help your provider understand your behavioral health.',
  lets_go: 'Let’s Go',
  very_good_last_step:
    'Very good, {name}! This last step is a series of questions. Just answer with your first thoughts.',

  // intro
  intro1_title: 'Welcome!',
  intro1_text: 'Menlo College and Ellipsis Health invite you to participate in an emotional health awareness campaign.\n\nWith just a few minutes of speech, you will receive a score that measures your emotional health.',
  intro1_button: 'Next',
  intro2_title: '',
  intro2_text: 'Before we begin, know we take your privacy seriously!\n\nYour scores are only available to you, and you control who sees them. You can choose to email the results to yourself.\n\nMenlo College will not have access to your results, unless you decide to share them with the Mental Health Services team.',
  intro2_button: "Let's Get Started",

  // header
  back: 'Back',

  // patient form
  first_name: 'First Name:',
  last_name: 'Last Name:',
  birth_year: 'Birth Year:',
  gender: 'Gender:',
  email: 'Email:',
  clinic_name: 'Clinic Name:',
  mrn: 'MRN:',
  view_ellipsis_health_patient_permissions:
    'View Ellipsis Health Permissions',
  this_information_lets_us_associate_your_information:
    'This information lets us associate your information with your provider. It can be viewed and updated in the Main Menu, if needed.',
  save_info: 'Save Info',
  saving: 'Saving...',

  // patient info
  are_you_sure_log_out: 'Are you sure you want to log out?',
  log_out: 'Log Out',
  cancel: 'Cancel',
  name: 'Name:',
  ellipsis_health_app_version: 'Ellipsis Health App Version:',
  view_privacy_policy: 'View Privacy Policy',
  contact_ellipsis_health: 'Contact Ellipsis Health',

  // reminder form
  when_do_you_want: 'When do you want to be reminded to take a session?',
  save: 'Save',

  // consent modal
  ellipsis_health_needs_your_permission:
    '"Ellipsis Health" needs your permission to proceed.',
  please_check_the_boxes_below:
    'Please check the boxes below to indicate your permissions.',
  agree_to: 'Agree to',
  terms_of_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  dont_allow: 'Don’t allow',
  allow: 'Allow',

  // privacy policy
  patient_permissions: 'Patient Permissions',

  // questionnaire
  question_of: 'Question {index} of {count}',

  // mood assessment
  very_good_lastly_we_love_to_know_state:
    'Very good, {name}! Lastly, we’d love to know your current state of mind.',
  which_one_of_these_emojis:
    'Which one of these emojis most closely matches your present mood:',
  thats_it: 'That’s it',

  // assessment result
  processing_result: 'Processing result...',
  great_job_set_session:
    '{name}, great job! Thank you for recording a session today.\n\nSessions need to be recorded once per week. If right now is a good day and time, then you’re all set.\n\nIf you want to adjust your scheduled session day and time, pick your values below…',
  great_job_thanks_for_recording:
    '{name}, great job! Thank you for recording a session today. Come back within a week!.',

  valid_session:
    '{name}, great job! Thank you for recording a session today.\n\nPlease come back to the Rising Higher when you see the notification to do your next session.',
  invalid_session:
    '{name}, unfortunately your session today was invalid because the recording time was not long enough and the surveys were skipped.\n\nPlease just give yourself about 3 minutes to do the session recording. You can try again right now, or wrap up for the day.',
  incomplete_session:
    'Eric, unfortunately your session today was incomplete because the recording time was not long enough. Thank you for completing survey.\n\nNext time, please just give yourself about 3 minutes for the Rising Higher session recording.',
  todays_assessment: 'Today’s Assessment',
  depression: 'Depression',
  anxiety: 'Anxiety',
  back_to_home: 'Back to Home',
  six_month_trend: '6-Month Trend',

  // recorder
  record: '',
  finish: '',
  pause: 'Pause',
  resume: '',
  just_a_bit_more: 'Just a Bit More',
  session_should_continue: 'Session should continue',
  please_record_at_least_minutes:
    'Please record {limit} full minutes of yourself talking',
  ending_result_invalid:
    'Ending recording before {limit2} minutes will provide an invalid session result. Please continue and speak for {limit} minutes if possible.',
  continue: 'Continue',
  keep_going: 'Keep Going',
  exit_early: 'Exit Early',
  pick_your_topic: 'Swipe to pick your topic:',
  select_topic_first: 'Pick your topic first',
  great_now_press_the_record_button:
    'Great! Now press the Record button below. Look here for questions to help you talk for four minutes...',
  swipe_through_the_topics: 'Question prompts will appear here.',
  select_a_new_topic: 'Select a new topic that is meaningful to you.',
  exit_recording: 'Exit Recording',
  finish_recording: 'Finish Recording',

  // tutorials
  tutorial_1:
    'We offer 7 topics to choose from, and you can swipe to see them all. Pick the topic of most personal interest to you.',
  tutorial_2:
    'You can change topics at any time. We also prompt you to change topics partway through.',
  tutorial_3:
    'We will show a variety of questions for each topic to help you keep talking. Start with the first one, then swipe for more.',
  tutorial_4:
    'Press the RECORD button to record, pause, and finish. Please speak clearly! Watch the waveform to see that we can hear you.',
  tutorial_5:
    'If you need to end the session early for some reason, you can press the Exit Recording button.',
  prev: 'Prev',
  next: 'Next',
  done: 'Done',

  // record help
  recording_will_resume: 'Press to start recording',
  press_record_to_begin: 'Press to start recording',
  all_set_press_finish: 'Press "Finish Recording" when done',
  consider_picking_a_new_topic: 'Consider picking a new topic now...',
  scroll_to_speak_to_more_questions: 'Swipe above for more prompts',
  good_job_almost_done: 'Good job, almost done...',
  great_job_halfway_done: 'Great job, halfway done...',
  good_job_keep_it_up: 'Good job, keep it up!',
  you_are_doing_great_keep_going: 'Great job, Keep going...',

  // topic
  'Health Concerns': 'Health Concerns',
  'Taking Care of Yourself': 'Taking Care of Yourself',
  'Your Support Structure': 'Your Support Structure',
  'State Of Your Life': 'State Of Your Life',
  'Living Situation': 'Living Situation',
  Work: 'Work',
  'Biggest Concern': 'Biggest Concern',

  // health concern
  'How is your physical and/or emotional health right now?':
    'How is your physical and/or emotional health right now?',
  'What ideas do you have to improve your health?':
    'What ideas do you have to improve your health?',
  'To improve your health what help would be needed?':
    'To improve your health what help would be needed?',
  'What are ways that you coped with physical or emotional health issues?':
    'What are ways that you coped with physical or emotional health issues?',
  'When you become stressed, how does that affect your health?':
    'When you become stressed, how does that affect your health?',

  // Taking Care of Yourself
  'Lately, how are you taking care of yourself?':
    'Lately, how are you taking care of yourself?',
  'What is one simple thing over the next week you could do to improve self care?':
    'What is one simple thing over the next week you could do to improve self care?',
  'If self care is difficult sometimes, please explain why.':
    'If self care is difficult sometimes, please explain why.',
  'Have you received any advice on how to improve your self care?':
    'Have you received any advice on how to improve your self care?',
  'What forms of self care have worked well for you in the past?':
    'What forms of self care have worked well for you in the past?',

  // Your Support Structure
  'Please tell me about your biggest support in your life now?':
    'Please tell me about your biggest support in your life now?',
  'Is there anybody else who’s been a big support in your life?':
    'Is there anybody else who’s been a big support in your life?',
  'Is there anything you’d like to do or say to show your appreciation for the support you’re receiving':
    'Is there anything you’d like to do or say to show your appreciation for the support you’re receiving',
  'How do you support those close to you?':
    'How do you support those close to you?',

  // State Of Your Life
  'What is your position on your life?': 'What is your position on your life?',
  'If your outlook on life is better, what is helping?':
    'If your outlook on life is better, what is helping?',
  'What is a simple step to make life a little better?':
    'What is a simple step to make life a little better?',
  'Share some hopes and dreams that will make your outlook on life better.':
    'Share some hopes and dreams that will make your outlook on life better.',
  'If your outlook on life is a little down, what is one thing that would make life better?':
    'If your outlook on life is a little down, what is one thing that would make life better?',

  // Living Situation
  'Elaborate on your life at home?': 'Elaborate on your life at home?',
  'What would you do differently to improve your home life?':
    'What would you do differently to improve your home life?',
  'Please describe your ideal home life.':
    'Please describe your ideal home life.',
  'Please describe any stressors happening at home recently.':
    'Please describe any stressors happening at home recently.',

  // Work
  'What does your job entail and elaborate about your work?':
    'What does your job entail and elaborate about your work?',
  'How do you feel about your colleagues?':
    'How do you feel about your colleagues?',
  'What are some stressful aspects of your work that you do not enjoy?':
    'What are some stressful aspects of your work that you do not enjoy?',
  "Name one or more aspects of your job that you'd love to change.":
    "Name one or more aspects of your job that you'd love to change.",

  // Biggest Concern
  'At this moment in your life, what concerns you the most?':
    'At this moment in your life, what concerns you the most?',
  'How is your life affected by this biggest concern?':
    'How is your life affected by this biggest concern?',
  'How long have you struggled with this concern?':
    'How long have you struggled with this concern?',
  'In what ways are you able to make the situation better?':
    'In what ways are you able to make the situation better?'
};
