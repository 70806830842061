import random from 'lodash/random';

// Swapping values around for a better visual effect
const VISUALIZATION_MAP = {
  0: 15,
  1: 10,
  2: 8,
  3: 9,
  4: 6,
  5: 5,
  6: 2,
  7: 1,
  8: 0,
  9: 4,
  10: 3,
  11: 7,
  12: 11,
  13: 12,
  14: 13,
  15: 14
};

const VISUALIZATION_COUNT = 16;

export default class AudioVisualizer {
  constructor(audioContext, audioSource, visualMainElement) {
    this.addVisualElements(visualMainElement);

    this.audioContext = audioContext;
    this.audioSource = audioSource;

    this.analyser = this.audioContext.createAnalyser();
    this.analyser.smoothingTimeConstant = 0.5;
    this.analyser.fftSize = 32;

    this.initRenderLoop(this.analyser);
  }

  addVisualElements = visualMainElement => {
    // Creating initial DOM elements
    visualMainElement.innerHTML = '';

    for (let i = 0; i < VISUALIZATION_COUNT; i += 1) {
      const elm = document.createElement('div');
      visualMainElement.appendChild(elm);
    }

    this.visualElements = visualMainElement.querySelectorAll('div');
  };

  processFrame = data => {
    const values = Object.values(data);

    // skip visualization if no data input is made
    if (values.every(v => v === 0)) {
      return;
    }

    for (let i = 0; i < VISUALIZATION_COUNT; i += 1) {
      const value =
        Math.min(values[VISUALIZATION_MAP[i]] + random(0, 20), 255) / 255;
      const elmStyles = this.visualElements[i].style;
      elmStyles.transform = `scaleY(${value})`;
      elmStyles.opacity = Math.max(0.25, value);
    }
  };

  start = () => {
    this.audioSource.connect(this.analyser);
  };

  stop = () => {
    this.audioSource.disconnect();
  };

  initRenderLoop = () => {
    const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
    const processFrame = this.processFrame || (() => {});

    const renderFrame = () => {
      this.analyser.getByteFrequencyData(frequencyData);
      processFrame(frequencyData);

      requestAnimationFrame(renderFrame);
    };
    requestAnimationFrame(renderFrame);
  };
}
