import { createReducer, createActions } from 'reduxsauce';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
  reset: ['reset'],
  setLoaded: ['loaded'],
  setUser: ['user'],
  startup: ['user', 'hasLoggedInNow'],
  setPartner: ['partner'],
  setSessions: ['sessions'],
  setGadQuestions: ['gadQuestions'],
  setPhqQuestions: ['phqQuestions'],
  setCustomQuestions: ['customQuestions'],
  setStep: ['step'],
  setTopics: ['topics'],
  setNewComer: ['newComer'],
  loadClinicians: ['partner'],
  setClinicians: ['clinicians']
});

export const AppTypes = Types;

export default Creators;

/* --------------------- Selectors ---------------- */
export const AppSelectors = {
  selectLoaded: state => state.app.loaded,
  selectUser: state => state.app.user,
  selectStep: state => state.app.step,
  selectTopics: state => state.app.topics,
  selectNewComer: state => state.app.newComer,
  selectPartner: state => state.app.partner,
  selectSessions: state => state.app.sessions,
  selectClinicians: state => state.app.clinicians,
  selectGadQuestions: state => state.app.gadQuestions,
  selectPhqQuestions: state => state.app.phqQuestions,
  selectCustomQuestions: state => state.app.customQuestions
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
  loaded: false,
  user: null,
  newComer: false,
  partner: null,
  step: 0,
  topics: [],
  clinicians: [],
  sessions: [],
  gadQuestions: [],
  phqQuestions: [],
  customQuestions: []
};

/* ------------------- Reducers --------------------- */
export const setLoaded = (state, { loaded }) => ({
  ...state,
  loaded
});

export const setUser = (state, { user }) => ({
  ...state,
  user
});

export const setStep = (state, { step }) => ({
  ...state,
  step
});

export const setPartner = (state, { partner }) => ({
  ...state,
  partner
});

export const setSessions = (state, { sessions }) => ({
  ...state,
  sessions
});

export const setNewComer = (state, { newComer }) => ({
  ...state,
  newComer
});

export const setTopics = (state, { topics }) => ({
  ...state,
  topics
});

export const setClinicians = (state, { clinicians }) => ({
  ...state,
  clinicians
});

export const setGadQuestions = (state, { gadQuestions }) => ({
  ...state,
  gadQuestions
});

export const setPhqQuestions = (state, { phqQuestions }) => ({
  ...state,
  phqQuestions
});

export const setCustomQuestions = (state, { customQuestions }) => ({
  ...state,
  customQuestions
});

export const reset = () => ({ ...INITIAL_STATE, loaded: true });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADED]: setLoaded,
  [Types.SET_USER]: setUser,
  [Types.SET_STEP]: setStep,
  [Types.SET_PARTNER]: setPartner,
  [Types.SET_SESSIONS]: setSessions,
  [Types.SET_NEW_COMER]: setNewComer,
  [Types.SET_TOPICS]: setTopics,
  [Types.SET_CLINICIANS]: setClinicians,
  [Types.SET_GAD_QUESTIONS]: setGadQuestions,
  [Types.SET_PHQ_QUESTIONS]: setPhqQuestions,
  [Types.SET_CUSTOM_QUESTIONS]: setCustomQuestions,
  [Types.RESET]: reset
});
