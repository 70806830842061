import { take, call, put, select } from 'redux-saga/effects';
import sessionService from 'services/session';
import logger from 'services/logger';
import * as STAGES from 'constants/stages';
import AppActions, { AppSelectors } from './AppRedux';
import RecordingActions, { RecordingSelectors } from './RecordingRedux';

export function* setTopic(actions) {
  const { topic } = actions;
  if (topic) {
    const allTopics = yield select(AppSelectors.selectTopics);
    const data = allTopics.find(t => t.id === topic);
    yield put(RecordingActions.setQuestions(data.openEndedQuestions));
    yield put(RecordingActions.setQuestionId(data.openEndedQuestions[0].id));
  }
}

export function* startRecording() {
  const user = yield select(AppSelectors.selectUser);
  const startTimestamp = yield select(RecordingSelectors.selectStartTimestamp);
  const sessionId = yield call(sessionService.create, {
    uid: user.uid,
    partnerName: user.partner,
    startTimestamp,
    phq: [],
    gad: [],
    phqScore: 0,
    gadScore: 0
  });
  yield put(RecordingActions.setLastSession(null));
  yield put(RecordingActions.setSessionId(sessionId, startTimestamp));
}

export function* saveRecording(actions) {
  const { blob, duration } = actions;
  const recordCount = yield select(RecordingSelectors.selectRecordCount);
  const sessionId = yield select(RecordingSelectors.selectSessionId);
  const user = yield select(AppSelectors.selectUser);
  const questionId = yield select(RecordingSelectors.selectQuestionId);
  yield put(RecordingActions.setSaving(true));

  logger.log('Storing record', sessionId, questionId, recordCount, duration);
  yield put(RecordingActions.setDuration(questionId, Math.ceil(duration)));
  try {
    yield call(
      sessionService.storeRecording,
      user.uid,
      sessionId,
      questionId,
      recordCount,
      blob,
      Math.ceil(duration)
    );
  } catch (e) {
    console.error(e);
  }
  yield put(RecordingActions.setSaving(false));
}

export function* finishRecording() {
  const saving = yield select(RecordingSelectors.selectSaving);

  yield put(RecordingActions.setFinishing(true));

  if (saving) {
    const s = yield take('SET_SAVING');
    console.log(s, saving);
  }

  const sessionId = yield select(RecordingSelectors.selectSessionId);
  const startTimestamp = yield select(RecordingSelectors.selectStartTimestamp);
  const recordingDuration = yield select(RecordingSelectors.selectAllDurations);
  const endTimestamp = new Date();
  const sessions = yield select(AppSelectors.selectSessions);
  const surveyDuration = Object.keys(recordingDuration).reduce((prev, qId) => {
    return prev + recordingDuration[qId];
  }, 0);
  yield call(sessionService.update, sessionId, {
    endTimestamp,
    recordingDuration,
    completed: surveyDuration >= 120,
    surveyDuration
  });
  yield put(
    RecordingActions.setLastSession({
      id: sessionId,
      endTimestamp,
      recordingDuration,
      completed: surveyDuration >= 120,
      surveyDuration
    })
  );

  logger.log('Finished recording', sessionId, recordingDuration);

  yield put(
    AppActions.setSessions([
      {
        startTimestamp: {
          seconds: (startTimestamp || new Date()).valueOf() / 1000
        }
      },
      ...sessions
    ])
  );

  yield put(RecordingActions.setFinishing(false));

  // go to next step
  yield put(AppActions.setStep(STAGES.SURVEY));
}

export function* loadSessionResult() {
  const sessionId = yield select(RecordingSelectors.selectSessionId);
  const sessions = yield select(AppSelectors.selectSessions);

  yield put(RecordingActions.setResultLoading(true));
  yield put(RecordingActions.setSessionResultError(null));
  yield put(RecordingActions.setSessionResult(null));

  try {
    const session = yield call(sessionService.loadScore, sessionId);
    yield put(AppActions.setSessions([session, ...sessions]));

    yield put(RecordingActions.setSessionResult(session));
  } catch (e) {
    yield put(RecordingActions.setSessionResultError(e.message));
  }

  yield put(RecordingActions.setResultLoading(false));
}
