import React from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import { Button } from 'rebass';
import { FormattedMessage } from 'react-intl';
import { colors } from 'styles/theme';

const STEPS = [
  {
    selector: '.topic-carousel',
    content: <FormattedMessage id="tutorial_1" />
  },
  {
    selector: '.topic-carousel',
    content: <FormattedMessage id="tutorial_2" />
  },
  {
    selector: '.question-carousel',
    content: <FormattedMessage id="tutorial_3" />
  },
  {
    selector: '.recorder-container',
    content: <FormattedMessage id="tutorial_4" />
  },
  {
    selector: '.exit-button',
    content: <FormattedMessage id="tutorial_5" />
  }
];

function Tutorial({ onClose, isOpen }) {
  const prevButton = (
    <Button as="span" variant="text">
      &lt;&nbsp;
      <FormattedMessage id="prev" />
    </Button>
  );
  const nextButton = (
    <Button as="span" variant="text">
      <FormattedMessage id="next" />
      &nbsp;&gt;
    </Button>
  );
  const doneButton = (
    <Button as="span" variant="text">
      <FormattedMessage id="done" />
    </Button>
  );
  return (
    <Tour
      steps={STEPS}
      showNumber={false}
      isOpen={isOpen}
      accentColor={colors.blue}
      onRequestClose={onClose}
      prevButton={prevButton}
      nextButton={nextButton}
      lastStepNextButton={doneButton}
    />
  );
}

Tutorial.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool
};

export default Tutorial;
