import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'components/form';
import { Modal, ModalHeader, Button } from 'components/common';
import { Box, Flex, Text } from 'rebass';
import { FormattedMessage } from 'react-intl';

function ConsentModal({ onClose, onProceed, ...rest }) {
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);

  return (
    <Modal {...rest}>
      <Box p={20}>
        <Text mb={10} fontSize={2}>
          <FormattedMessage id="ellipsis_health_needs_your_permission" />
        </Text>
        <Text mb={20} fontSize={2}>
          <FormattedMessage id="please_check_the_boxes_below" />
        </Text>
        <Flex alignItems="center" mb={10}>
          <Checkbox checked={agreeTerm} onChange={setAgreeTerm} />
          <Text ml={10}>
            <Text
              variant="link"
              color="brandDark"
              as="a"
              href="/terms-of-service.pdf"
              target="_blank"
              ml={5}
            >
              <FormattedMessage id="terms_of_service" />
            </Text>
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Checkbox checked={agreePrivacy} onChange={setAgreePrivacy} />
          <Text ml={10}>
            <Text
              variant="link"
              color="brandDark"
              as="a"
              href="/privacy-policy.pdf"
              target="_blank"
              ml={5}
            >
              <FormattedMessage id="privacy_policy" />
            </Text>
          </Text>
        </Flex>
      </Box>
      <Flex flexDirection="row" justifyContent="center" p={10}>
        <Button variant="text" width="40%" onClick={onClose}>
          <FormattedMessage id="dont_allow" />
        </Button>
        <Button
          variant="containedPrimary"
          width="40%"
          ml={10}
          onClick={onProceed}
          disabled={!agreeTerm || !agreePrivacy}
        >
          <FormattedMessage id="allow" />
        </Button>
      </Flex>
    </Modal>
  );
}

ConsentModal.propTypes = {
  onClose: PropTypes.func,
  onProceed: PropTypes.func
};

export default ConsentModal;
