import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Modal, Button } from 'components/common';
import { Checkbox } from 'components/form';
import { Box, Flex, Text, Image } from 'rebass';
import firebase from 'services/firebase';

const STEPS = [
  'Swipe to find a TOPIC you like and press to select.  You can change your topic whenever you like.',
  'Questions under each topic are just a guide to help you speak your thoughts and feelings. This app is a place you can vent with no judgment.',
  'Press Record inside the button to start speaking. Speak for 3 minutes to close the ring.  Aim to speak to two different topics. Ready? Set…  '
];

const SIZE = [180, 120, 120];

function OrientationModal({ onClose, onProceed, isLoading, ...rest }) {
  const [index, setIndex] = useState(0);
  const [orientation, setOrientation] = useState(false);

  firebase.logEvent('eh_screen_view', {event_category: 'orientation'});

  return (
    <Modal {...rest} className="orientationModal">
      <Box bg="metallicBlue" p={10} sx={{ borderRadius: 'xs' }}>
        <Text color="white" fontSize="24px" fontWeight="bold">
          Instruction {index + 1} of 3
        </Text>
        <SwipeableViews
          enableMouseEvents
          index={index}
          onChangeIndex={setIndex}
        >
          {STEPS.map((q, i) => (
            <Box v key={i}>
              <Flex alignItems="center" justifyContent="center" height={150}>
                <Image
                  src={`${process.env.PUBLIC_URL}/orientation/${i + 1}.svg`}
                  height={SIZE[i]}
                />
              </Flex>
              <Box variant="card2" mx={10} mb={20} bg="white">
                {q}
              </Box>
            </Box>
          ))}
        </SwipeableViews>
        <Box variant="dotlist" mb={10}>
          {STEPS.map((q, i) => (
            <Box
              key={`dot_${i}`}
              variant={index === i ? 'dotselected' : 'dot'}
              onClick={() => setIndex(i)}
            />
          ))}
        </Box>
        <Text color="white" textAlign="center" mb={20}>
          {index === 2
            ? 'Swipe right for previous instructions'
            : 'Swipe left for next instruction'}
        </Text>
        <Flex flexDirection="row" justifyContent="center" p={10}>
          <Button
            variant="containedPrimary"
            width="80%"
            ml={10}
            loadingText="Submitting..."
            loading={isLoading}
            sx={{
              visibility: index < 2 ? 'hidden' : 'visible'
            }}
            onClick={() => onProceed(orientation)}
          >
            Let's Go
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

OrientationModal.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onProceed: PropTypes.func
};

export default OrientationModal;
