import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Icon from '../Icon';

const ModalHeader = ({ onClose, ...props }) => (
  <Box variant="modalHeader" {...props}>
    <Text variant="headerTitle" textAlign="left" color="text1" mr={36} {...props} />
    {onClose && (
      <Icon
        as="span"
        svg={CloseIcon}
        size={18}
        fontSize={3}
        color="black"
        onClick={onClose}
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          right: 16,
          top: 26
        }}
      />
    )}
  </Box>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func
};

export default ModalHeader;
