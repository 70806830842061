import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { getIn } from 'formik';
import { FormGroup, Label, InvalidFeedback } from 'components/form';

const Field = ({ form, name, render, label, labelProps, submitCount }) => {
  const submitted = submitCount > 0;
  const touched = submitted || getIn(form.touched, name);
  let error = getIn(form.errors, name);
  const hasError = !!(touched && error);
  if (typeof error === 'object') {
    [error] = Object.values(error);
  }

  return (
    <FormGroup>
      {label && <Label {...labelProps}>{label}</Label>}
      <Box flex={1}>
        {render({ hasError, error })}
        {hasError && <InvalidFeedback>{error}</InvalidFeedback>}
      </Box>
    </FormGroup>
  );
};

Field.propTypes = {
  render: PropTypes.func,
  name: PropTypes.string,
  form: PropTypes.object,
  labelProps: PropTypes.object,
  label: PropTypes.node,
  submitCount: PropTypes.number
};

Field.defaultProps = {
  labelProps: {}
};

export default Field;
