import React from 'react';
import PropTypes from 'prop-types';
import { Text, Flex, Image } from 'rebass';

const ICON_MAP = {
  1: 'health',
  2: 'taking-care',
  3: 'support',
  4: 'state-life',
  5: 'living',
  6: 'work',
  7: 'concern'
};

function TopicCarousel({ id, selected, title, ...rest }) {
  const icon = `${process.env.PUBLIC_URL}/topic-icons/${ICON_MAP[id]}_unselected.svg`;
  return (
    <Flex variant={selected ? 'topicCardSelected' : 'topicCard'} {...rest}>
      <Image src={icon} />
      <Text variant="sectionTitle" color="navyBlue">
        {title}
      </Text>
    </Flex>
  );
}

TopicCarousel.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.node
};

export default TopicCarousel;
