export default async function createMicSource() {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = new AudioContext();

  const stream = await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false
  });
  const source = audioContext.createMediaStreamSource(stream);
  return {
    audioContext,
    stream,
    source
  };
}

export async function isMicAllowed() {
  return navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false
  });
}
