import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import { Flex, Text, Box } from 'rebass';
import { Button, AlertPanel, FluidContainer, Logo } from 'components/common';
import { InputField } from 'components/formik';
import text_schema from './text_schema';
import call_schema from './call_schema';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';

const INITIAL_VALUES = {
  code: ''
};

class CodeForm extends Component {
  handleSubmit = async (values, actions) => {
    const { onSubmit } = this.props;

    try {
      await onSubmit(values.code);
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  renderForm = ({ isSubmitting, isValid }) => {
    const { phone, onReset, option, error, onRetry, onClear, disabled } = this.props;
    const retryBtnText = (option)? "Call me again with a Verification Code" : "Resend me a Verification Code"

    return (
      <FluidContainer flexDirection="column" flex={1} as={Form}>
        <Flex p={30} flexDirection="column" alignItems="center">
          <Logo icon={EmailIcon} />
        </Flex>
        <Text textAlign="center" fontSize={5} fontWeight={'bold'}>
          Check your email
        </Text>
        <Text textAlign="center" p={20} fontSize={2} >
          Enter the Verification Code sent to your email.
        </Text>

        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          my={20}
          width={1}
        >
          <Field component={InputField} name="code" type="text" placeholder="Verification Code" onKeyDown={onClear} />
          <AlertPanel fontSize='0.8em' children={error} mb={30} />
          <Button 
            mb={30}
            variant="text"
            onClick={onReset}
            type="button"
            fontSize={0}
          >
            Didn't receive an email?<br/>Go back to previous step
          </Button>
        </Flex>

        <Box py={20}>
          <Button
            variant="containedPrimary"
            disabled={disabled || !isValid}
            loading={disabled || isSubmitting}
            loadingText="Verifying..."
            type="submit"
          >
            Register
          </Button>
        </Box>
      </FluidContainer>
    );
  };

  render() {
    const { initialValues, option } = this.props;
    const schema = (option)? call_schema : text_schema;

    return (
      <Formik
        initialValues={{
          ...INITIAL_VALUES,
          ...initialValues
        }}
        onSubmit={this.handleSubmit}
        validationSchema={schema}
        isInitialValid
      >
        {this.renderForm}
      </Formik>
    );
  }
}

CodeForm.propTypes = {
  phone: PropTypes.string,
  option: PropTypes.number,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  onRetry: PropTypes.func,
  onClear: PropTypes.func,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CodeForm;
