export default {
  // splash
  a_new_vital_sign: 'Een nieuw vitaal teken',
  a_new_world: 'een nieuwe wereld',

  // app
  edit_reminder_time: 'Kies volgende afspraak',
  main_menu: 'Hoofdmenu',
  set_up_account: 'Account opzetten',
  most_recent_session: 'Meest Recente Sessie',
  total_sessions: 'Totaal Aantal Sessies',
  you_are_all_set: 'Je hebt genoeg opnames gemaakt, {name}.',
  it_is_time_to_record_a_session:
    'Hallo {name}, het is tijd voor een nieuwe sessie!\n\nGebruik a.u.b. geen Bluetooth koptelefoon of microfoon en gebruik de ingebouwde microfoon. Druk op de knop om de volgende opname te starten...',
  start_session: 'Start Sessie',
  thanks_for_starting_session: `Hallo {name}, dankjewel voor het starten van een sessie`,
  lets_record_your_voice:
    'Laten we maar eens een opname maken van je stem om je begeleider een idee te geven van je gedragsgezondheid.',
  lets_go: 'Laten we beginnen',
  very_good_last_step:
    'Heel goed, {name}! Dit is de laatste stap in een serie van vragen. Antwoord met de eerste gedachte die bij je boven komen.',

  // intro
  welcome_to_ellipsis:
    'Welkom bij de Ellipsis Health familie!\n\nWe hebben een standaard ontwikkeld voor een vitaal teken voor gedragsgezondheid.\n\nThe Ellipsis Health App verzamelt een real-time, spraakgebaseerde beoordeling van ongerustheid en depressie.',
  got_it: 'OK',
  our_notification_will_prompt:
    'Onze prompt zal je eraan herinneren om wekelijks een monster van je stem op te nemen.\n\nEllipsis Health zal een analyse van jouw stem naar je dokter sturen zodat hij/zij op de hoogte blijft van je status.\n\nOm te beginnen, laten we je officiele toestemming krijgen en je account opzetten...',
  lets_do_it: 'Gaan met die banaan',

  // header
  back: 'Terug',

  // patient form
  first_name: 'Voornaam:',
  last_name: 'Achternaam:',
  birth_year: 'Geboortejaar:',
  gender: 'Geslacht:',
  clinic_name: 'Naam van de kliniek:',
  mrn: 'Patientnummer:',
  view_ellipsis_health_patient_permissions:
    'Bekijk Ellipsis Health Patient Permissies',
  this_information_lets_us_associate_your_information:
    'Deze info laat ons jouw informatie met die van de kliniek associeren. Deze kan in het Hoofdmenu bekenen en veranderd worden, indien nodig.',
  save_info: 'Informatie opslaan',
  saving: 'Aan het opslaan...',

  // patient info
  are_you_sure_log_out: 'Weet je zeker dat je uit wil loggen?',
  log_out: 'Uitloggen',
  cancel: 'Stoppen',
  name: 'Naam:',
  ellipsis_health_app_version: 'Ellipsis Health App Versie:',
  view_privacy_policy: 'Bekijk Privacy Document',
  contact_ellipsis_health: 'Contacteer Ellipsis Health',

  // reminder form
  when_do_you_want:
    'Wanneer wil je een herinnering krijgen voor de volgende sessie?',
  save: 'Opslaan',

  // consent modal
  ellipsis_health_needs_your_permission:
    '"Ellipsis Health" heeft jouw toestemming nodig om door te gaan.',
  please_check_the_boxes_below:
    'Vink de volgende documenten aan om jouw toestemming aan te geven. Deze gedragscodes kun je ook op elk moment vanuit het Hoofdmenu bekijken.',
  agree_to: 'Geef toestemming',
  terms_of_service: 'De kleine lettertjes',
  privacy_policy: 'Privacy Document',
  dont_allow: 'Niet toegestaan',
  allow: 'Toegestaan',

  // privacy policy
  patient_permissions: 'Patient Toestemming',

  // questionnaire
  question_of: 'Vraag {index} van {count}',

  // mood assessment
  very_good_lastly_we_love_to_know_state:
    'Heel goed, {name}! En als laatste willen we graag weten hoe je je voelt.',
  which_one_of_these_emojis:
    'Welkle van deze emojis lijkt het meest op hoe je je voelt...',
  thats_it: 'Klaar!',

  // assessment result
  processing_result: 'Berekenen van het resultaat...',
  great_job_thanks_for_recording:
    '{name}, goed gedaan! Dankjewel voor het doen van een sessie vandaag. Kom a.u.b. terug over een week!\n\nJe kunt je resultaten hierbeneden zien.',
  todays_assessment: 'Beoordeling van vandaag',
  depression: 'Depressie',
  anxiety: 'Ongrustheid',
  back_to_home: 'Terug naar het begin',
  six_month_trend: 'Trend van 6 maanden',

  // recorder
  record: 'Record',
  finish: 'Einde',
  pause: 'Pause',
  resume: 'Doorgaan',
  just_a_bit_more: 'Een klein beetje meer',
  please_record_at_least_minutes:
    'Neem alsjeblieft op zijn minst {limit} minuten aan monoloog op',
  keep_going: 'Ga door',
  pick_your_topic: 'Selekteer je gespreksonderwerp:',
  great_now_press_the_record_button:
    'Prima! Druk op de Record knop hierbeneden. Kijk hier voor vragen die je kunnen helpen om vier minuten te praten...',
  swipe_through_the_topics:
    'Veeg door de onderwerpen hierboven om je keuze te selecteren, kies eentje om te beginnen.',
  select_a_new_topic: 'Selecteer een nieuw onderwerp.',
  exit_recording: 'Stop de opname',

  // tutorials
  tutorial_1:
    'Wij geven 7 onderwerpen aan waartussen je kunt kiezen, en je kunt links en rechts vegen om ze allemaal te zien. Kies het onderwerp dat je het meest interesseert om over te praten.',
  tutorial_2:
    'Je kunt op elk moment van onderwerp veranderen. We zullen je op een gegeven moment ook aangeven dat je van ondewerp moet veranderen.',
  tutorial_3:
    'We geven je een mix van vragen voor elk onderwerp om je op gang te helpen. Begin met de eerste, en veeg door om er meer te zien.',
  tutorial_4:
    'Druk op de RECORD knop om de opname te starten, pauseren, en beeindigen. Spreek alsjeblieft duidelijk! Kijk af en toe naar de bewegende grafiek om te zien of we je goed kunnen horen.',
  tutorial_5:
    'Als je de sessie om een of andere reden vervroegd moet beeindigen, kun je op de Einde knop drukken.',
  prev: 'Vorige',
  next: 'Volgende',
  done: 'Klaar',

  // record help
  recording_will_resume:
    'De opname gaat door zodra je een ander onderwerp kiest',
  press_record_to_begin: 'Druk op Record om te beginnen',
  all_set_press_finish:
    'Helemaal klaar! Druk op "Einde" of "Einde opname" zodra je klaar bent...',
  consider_picking_a_new_topic: 'Probeer eens een ander onderwerp...',
  scroll_to_speak_to_more_questions:
    'Veeg om over andere vragen te beantwoorden...',
  good_job_almost_done: 'Goed gedaan, bijna klaar...',
  great_job_halfway_done: 'Goedzo, halverwege...',
  good_job_keep_it_up: 'Ga lekker door!',
  you_are_doing_great_keep_going: 'Dat gaat lekker zo! Ga door...',

  // topic
  'Health Concerns': 'Gezondheid',
  'Taking Care of Yourself': 'Zelfzorg',
  'Your Support Structure': 'Levensondersteuning',
  'State Of Your Life': 'Levensomstandigheden',
  'Living Situation': 'Leefomgeving',
  Work: 'Werk',
  'Biggest Concern': 'Grootste Zorg',

  // health concern
  'How is your physical and/or emotional health right now?':
    'Hoe is je fysieke en/of emotionele gezondheid op dit moment?',
  'What ideas do you have to improve your health?':
    'Wat voor ideeen heb je om gezonder te worden?',
  'To improve your health what help would be needed?':
    'Wat voor hulp heb je nodig om gezonder te worden?',
  'What are ways that you coped with physical or emotional health issues?':
    'Op wat voor manieren ben je vroeger omgegaan met fysieke of emotionele gezondheidsproblemen?',
  'When you become stressed, how does that affect your health?':
    'Hoe verandert stress jouw gezondheid?',

  // Taking Care of Yourself
  'Lately, how are you taking care of yourself?':
    'Hoe zorg je voor jezelf recentelijk?',
  'What is one simple thing over the next week you could do to improve self care?':
    'Wat is iets simpels dat je de komende week kunt doen om beter voor jezelf te zorgen?',
  'If self care is difficult sometimes, please explain why.':
    'Als zorgen voor jezelf af en toe moeilijk is, geef alsjeblieft aan waarom.',
  'Have you received any advice on how to improve your self care?':
    'Heb je wel eens advies gekregen over hoe beter voor jezelf te zorgen?',
  'What forms of self care have worked well for you in the past?':
    'Op wat voor manieren heb je in het verleden voor jezelf gezorgd?',

  // Your Support Structure
  'Please tell me about your biggest support in your life now?':
    'Vertel me alsjeblieft over diegene die je het meest steunt in het leven?',
  'Is there anybody else who’s been a big support in your life?':
    'Is er iemand anders die een grote steun is in jouw leven?',
  'Is there anything you’d like to do or say to show your appreciation for the support you’re receiving':
    'Is er iets dat je wil zeggen om jouw dankbaarheid voor de ondersteuning die je krijgt te tonen?',
  'How do you support those close to you?':
    'Hoe ondersteun jij jouw dierbaren?',

  // State Of Your Life
  'What is your position on your life?': 'Hoe kijk je naar je eigen leven?',
  'If your outlook on life is better, what is helping?':
    'Als je een positivere kijk hebt gekregen, wat heeft daaraan geholpen?',
  'What is a simple step to make life a little better?':
    'Wat is een simpele stap om je leven een beetje beter te maken?',
  'Share some hopes and dreams that will make your outlook on life better.':
    'Beschrijf eens de verlangens en dromen die je blik op jouw leven kunnen verbeteren.',
  'If your outlook on life is a little down, what is one thing that would make life better?':
    'Als je blik of het leven wat negatiever is, wat is iets dat jouw leven kan verbeteren?',

  // Living Situation
  'Elaborate on your life at home?': 'Vertel iets over je thuissituatie?',
  'What would you do differently to improve your home life?':
    'Wat zou je kunnen veranderen om je thuissituatie te verbeteren?',
  'Please describe your ideal home life.':
    'Beschrijf eens je ideale thuissituatie.',
  'Please describe any stressors happening at home recently.':
    'Vertel eens over recentelijke stressgevoelige situaties thuis.',

  // Work
  'What does your job entail and elaborate about your work?':
    'Wat doe je voor werk en vertel eens iets meer over je werk?',
  'How do you feel about your colleagues?': "Hoe voel je je over je collega's?",
  'What are some stressful aspects of your work that you do not enjoy?':
    'Wat zijn sommige van de meer stressgevoelige dingen aan je werk die je niet leuk vind?',
  "Name one or more aspects of your job that you'd love to change.":
    'Geef een of meer aspekten die aan je werk zitten die je graag zou veranderen.',

  // Biggest Concern
  'At this moment in your life, what concerns you the most?':
    'Wat zijn op dit moment je grootste zorgen?',
  'How is your life affected by this biggest concern?':
    'Hoe wordt jouw leven beinvloed door deze zorgen?',
  'How long have you struggled with this concern?':
    'Hoe lang heeft deze grootste zorg je dwars gezeten?',
  'In what ways are you able to make the situation better?':
    'Op wat voor manieren ben je bezig om de situatie beter te maken?'
};
