import { all, takeLatest } from 'redux-saga/effects';
import { AppTypes } from './AppRedux';
import { RecordingTypes } from './RecordingRedux';
import { startup, loadClinicians } from './AppSaga';
import {
  setTopic,
  startRecording,
  saveRecording,
  finishRecording,
  loadSessionResult
} from './RecordingSaga';

export default function* root() {
  yield all([takeLatest(AppTypes.STARTUP, startup)]);
  yield all([takeLatest(AppTypes.LOAD_CLINICIANS, loadClinicians)]);
  yield all([takeLatest(RecordingTypes.SET_TOPIC, setTopic)]);
  yield all([takeLatest(RecordingTypes.START_RECORDING, startRecording)]);
  yield all([takeLatest(RecordingTypes.SAVE_RECORDING, saveRecording)]);
  yield all([takeLatest(RecordingTypes.FINISH_RECORDING, finishRecording)]);
  yield all([
    takeLatest(RecordingTypes.LOAD_SESSION_RESULT, loadSessionResult)
  ]);
}
