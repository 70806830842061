// @TODO move configurations to .env
export default function getConfig() {
  let config;

  if (process.env.REACT_APP_ENV === 'production') {
    // In case of kittreskin project
    config = {
      apiKey: 'AIzaSyDJkiNNKVU5OG1UUMgn1oRptUtAqTHp_Dc',
      authDomain: 'ellipsis-app-kittreskin.firebaseapp.com',
      databaseURL: 'https://ellipsis-app-kittreskin.firebaseio.com',
      projectId: 'ellipsis-app-kittreskin',
      storageBucket: 'ellipsis-app-kittreskin.appspot.com',
      messagingSenderId: '951538236459',
      appId: "1:951538236459:web:8078f22523b33e7100be6e",
      measurementId: "G-KP2NX1YWLL"
    };
  } else if (process.env.REACT_APP_ENV === 'qa') {
    // In case of staging project
    config = {
      apiKey: "AIzaSyDRMiM1_i_QhlwoR08Zez0b-cnCyr-EoP0",
      authDomain: "rising-higher-qa.firebaseapp.com",
      databaseURL: "https://rising-higher-qa.firebaseio.com",
      projectId: "rising-higher-qa",
      storageBucket: "rising-higher-qa.appspot.com",
      messagingSenderId: "922833795723",
      appId: "1:922833795723:web:c60fcf828db1c9aea8328e",
      measurementId: "G-RT89VPSZVZ"
    };
  } else if (process.env.REACT_APP_ENV === 'demo') {
    // In case of staging project
    config = {
      apiKey: 'AIzaSyCYvmfvp7ctWS_U9K99Tnczw7efHHXEeFQ',
      authDomain: 'rising-higher-demo.firebaseapp.com',
      databaseURL: 'https://rising-higher-demo.firebaseio.com',
      projectId: 'rising-higher-demo',
      storageBucket: 'rising-higher-demo.appspot.com',
      messagingSenderId: '986521346920',
      appId: '1:986521346920:web:02179cdc0b7c4982faecdd',
      measurementId: 'G-L14NPGY8J9'
    };
  } else if (process.env.REACT_APP_ENV === 'qa') {
    config = {
      apiKey: "AIzaSyDRMiM1_i_QhlwoR08Zez0b-cnCyr-EoP0",
      authDomain: "rising-higher-qa.firebaseapp.com",
      databaseURL: "https://rising-higher-qa.firebaseio.com",
      projectId: "rising-higher-qa",
      storageBucket: "rising-higher-qa.appspot.com",
      messagingSenderId: "922833795723",
      appId: "1:922833795723:web:ba9a98627e686665a8328e",
      measurementId: "G-D04MGSW2H0"
    };
  } else if (process.env.REACT_APP_ENV === 'kittreskin-dev') {
    config = {
      apiKey: 'AIzaSyBU5pw8Tl2WEAKyT_tG-4ZEamHYKyJ3bm4',
      authDomain: 'rising-higher-dev.firebaseapp.com',
      databaseURL: 'https://rising-higher-dev.firebaseio.com',
      projectId: 'rising-higher-dev',
      storageBucket: 'rising-higher-dev.appspot.com',
      messagingSenderId: '889370886587',
      appId: '1:889370886587:web:b48d340faceb256ce2c49e',
      measurementId: 'G-H02Z84754W'
    };
  } else {
    // In case of test project
    config = {
      apiKey: 'AIzaSyCszDcIOsK7-xdMCgkDKq6HUkCN_TJPhgo',
      authDomain: 'ellipsisapp-test.firebaseapp.com',
      databaseURL: 'https://ellipsisapp-test.firebaseio.com',
      projectId: 'ellipsisapp-test',
      storageBucket: 'ellipsisapp-test.appspot.com',
      messagingSenderId: '296970955147'
    };
  }

  return config;
}
