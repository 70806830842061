import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Button, Text, Card } from 'rebass';
import { FluidContainer } from 'components/common';
import { FormattedMessage } from 'react-intl';

class Questionnaire extends Component {
  constructor() {
    super();
    this.state = {
      index: 0,
      result: []
    };
  }

  componentWillUnmount() {
    const { onSave, questions } = this.props;
    const { result } = this.state;
    if (result.length < questions.length) {
      onSave(result);
    }
  }

  handleAnswer = async answer => {
    const { questions, onSave } = this.props;
    const { index, result } = this.state;

    if (index < questions.length - 1) {
      this.setState({
        result: [...result, answer],
        index: index + 1
      });
    } else {
      this.setState({
        result: [...result, answer]
      });
      onSave([...result, answer]);
    }
  };

  render() {
    const { questions, userName } = this.props;
    const { index } = this.state;
    const currentQuestion = questions[index];
    const mt = (index == 0)?20:60;

    if (!currentQuestion) {
      console.warn('Question not found!');
      return null;
    }

    return (
      <>
        {(index == 0) &&
          (<FluidContainer pt={40}>
            <Card variant="surveyWelcomeCard">
              <FormattedMessage
                id="very_good_last_step"
                values={{ name: userName }}
              />
            </Card>
          </FluidContainer>)
        }
        <FluidContainer mt={mt} flex={1}>
          <Text variant="sectionTitle" fontSize={1}>
            <FormattedMessage
              id="question_of"
              values={{ index: index + 1, count: questions.length }}
            />
          </Text>
          <Text variant="sectionTitle" fontWeight='normal'>{currentQuestion.prompt}</Text>
        </FluidContainer>
        <FluidContainer>
          <Flex
            flex={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            pb={20}
          >
            {Object.keys(currentQuestion.answers).map(key => (
              <Button
                key={key}
                variant="surveyAnswerButton"
                onClick={() => {
                  this.handleAnswer({ type: currentQuestion.class, answer: key });
                }}
                mb={10}
                ml={0}
              >
                {currentQuestion.answers[key]}
              </Button>
            ))}
          </Flex>
        </FluidContainer>
      </>
    );
  }
}

Questionnaire.propTypes = {
  userName: PropTypes.string,
  questions: PropTypes.array,
  onSave: PropTypes.func
};

export default Questionnaire;
