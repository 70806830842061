import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';
import getConfig from '../config';

const config = getConfig();

firebase.initializeApp(config);
firebase.analytics()

export function logEvent(eventName, eventObj) {
  // here we can send the event to logger as well
  return firebase.analytics().logEvent(eventName, eventObj);
}

export function firestore() {
  return firebase.firestore();
}

export function storage() {
  return firebase.storage();
}

export function functions() {
  return firebase.functions();
}

export default {
  auth: firebase.auth,
  logEvent,
  storage,
  firestore,
  functions
};
