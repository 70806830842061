import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Card, Button, Text } from 'rebass';
import { FluidContainer, Logo } from 'components/common';

function Intro({ title, description, children, buttonText, onSubmit, disabled, pageCounter, icon }) {
  return (
    <>
      <Flex p={30} flexDirection="column" alignItems="center">
        <Logo icon={icon} />
      </Flex>
      <FluidContainer flex={1}>
        <Card variant="card_mc">
          {title && (
            <Text pb={30} sx={{fontSize: 4, fontWeight: 'bold'}}>
              {title}
            </Text>
          )}
          {description}
        </Card>
        {children}
      </FluidContainer>
      <FluidContainer py={20} alignItems="center">
        <Text mb={10}>
          {pageCounter}
        </Text>
        <Button
          variant="containedPrimary"
          onClick={onSubmit}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </FluidContainer>
    </>
  );
}

Intro.propTypes = {
  description: PropTypes.node,
  children: PropTypes.node,
  buttonText: PropTypes.node,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  pageCounter:PropTypes.string,
  icon: PropTypes.object
};

export default Intro;
